import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useLaunchDarklyIdentify = () => {
    const auth = useAuth();
    const ldClient = useLDClient();
    const [isLDIdentified, setIsLDIdentified] = useState<boolean | null>(null);

    useEffect(() => {
        if (ldClient && auth.user) {
            ldClient
                .identify({
                    kind: 'multi',
                    user: {
                        key: auth.user?.id,
                        name: `${auth.user?.firstName} ${auth.user?.lastName}`,
                        email: auth.user?.email,
                        accountType: auth.user?.accountType,
                        _meta: {
                            privateAttributes: ['email', 'name'],
                        },
                    },
                    orchard: {
                        key: auth.user?.tenantId,
                    },
                })
                .then(
                    () => setIsLDIdentified(true),
                    () => setIsLDIdentified(false)
                );
        } else {
            // We don't have an ldClient or auth.user, so we can't identify, so we'll just assume that the default flags are correct
            // This will happen when we going back on the app for the first time after this update.
            // Waiting for the refresh token or signing out and signing back in will fix this issue
            setIsLDIdentified(false);
        }
    }, [auth.user, ldClient]);

    return isLDIdentified;
};
