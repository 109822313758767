import lodash from 'lodash';
import { GoogleTokenPayload } from '@hectre/platform.common.packages/lib/constants';
import { Buffer } from 'buffer';
import { getErrorMessage } from './errors';
import { getLocalStorageItem, setLocalStorageItem, StorageKey } from './storage';
import { jwtDecode } from 'jwt-decode';
import {
    AccessToken,
    AuthDetails,
    AuthPermissionSet,
    AuthTenantDetails,
    AuthTenantFeatureSet,
    AuthTenantProductSet,
} from 'src/models/auth';
import { Metadata, TenantMobileOptions } from 'src/DataContracts/Metadata';
import { booleanOrFalse } from './boolean';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { LDFeatureFlag } from 'src/DataContracts/FeatureFlags';

/**
 * Extract the `idToken` from the wrapped JWT token (`AccessToken`).
 *
 * @returns The `GoogleTokenPayload` from the `idToken` section of the decoded JWT token
 */
export function getIdToken(): GoogleTokenPayload {
    const token = localStorage.getItem(StorageKey.Token);

    if (lodash.isEmpty(token)) {
        throw new Error('The Auth token was not found.');
    }

    const idToken = extractIdToken(token!);
    return jwtDecode(idToken) as GoogleTokenPayload;
}

/**
 * Extract the `idToken` (as a string) from the wrapped JWT token (`AccessToken`).
 *
 * @param refreshExpiredToken Check for token expiry, and refresh the token if it has expired (default = false)
 * @returns The `GoogleTokenPayload` from the `idToken` section of the decoded JWT token
 */
export async function getIdTokenAsString(auth: AuthDetails, refreshExpiredToken: boolean = false): Promise<string> {
    let token = localStorage.getItem(StorageKey.Token);

    if (lodash.isEmpty(token)) {
        throw new Error('The Auth token was not found.');
    }

    let idToken = extractIdToken(token!);

    if (refreshExpiredToken && hasTokenExpired(idToken)) {
        token = await auth.refreshToken();
        idToken = extractIdToken(token);
    }

    return idToken;
}

/**
 * Set token & refresh token in the local storage
 */
export const setTokenOnLocalStorage = (token: string, refreshToken?: string): void => {
    setLocalStorageItem(StorageKey.Token, token, true);
    setLocalStorageItem(StorageKey.RefreshToken, refreshToken ?? token, true);
};

/**
 * Return the `orchardId` from the active (stored) token
 */
export function getOrchardIdFromActiveToken(): string {
    try {
        const token = getLocalStorageItem(StorageKey.Token, true);
        if (lodash.isEmpty(token)) {
            throw new Error('Could not read the token');
        }

        const accessToken = parseJwtToken(token);
        if (lodash.isEmpty(accessToken.OrchardId)) {
            throw new Error('Could not read the orchardId from token');
        }

        return accessToken.OrchardId;
    } catch (error) {
        console.error(error);
        throw new Error(`Could not get orchardId: ${getErrorMessage(error)}`);
    }
}

/**
 * Parse the provided JWT token and return the `AccessToken`
 */
export function parseJwtToken(jwt: string): AccessToken {
    return JSON.parse(Buffer.from(jwt, 'base64').toString('utf8')) as AccessToken;
}

/**
 * The backend returns a wrapped `GoogleTokenPayload` JWT token (`AccessToken`).
 * Often we only need the inner part (the `idToken`).
 */
function extractIdToken(token: string): string {
    const decodedToken = parseJwtToken(token);

    if (!decodedToken['Auth'] || !decodedToken['Auth']['idToken'] || lodash.isEmpty(decodedToken['Auth']['idToken'])) {
        throw new Error('Could not read the ID token.');
    }

    const idToken = decodedToken['Auth']['idToken'];
    return idToken;
}

/**
 * Check if the provided JWT token has expired
 */
function hasTokenExpired(idToken: string): boolean {
    try {
        const decodedIdToken = jwtDecode(idToken) as GoogleTokenPayload;

        // Both Date.now() and JWT expiration time identify the time elapsed since January 1, 1970 00:00:00 UTC
        // JWT expiration time is in seconds, hence multiplying by 1000 to get milliseconds
        return Date.now() >= decodedIdToken.exp * 1000;
    } catch (error) {
        throw new Error(`Unable to check token expiry date: ${getErrorMessage(error)}`);
    }
}

// Tenants using Perform without picking/cherries/timesheet/scout don't have access to:
// Harvest, Payroll, Timesheets, Maps, Insights, Scout and most of the admin features
export const evaluatePerformFocusedTenant = (mobileOptions?: TenantMobileOptions): boolean => {
    return (
        mobileOptions?.perform === true &&
        !mobileOptions?.picking &&
        !mobileOptions?.cherries &&
        !mobileOptions?.newTimesheet &&
        !mobileOptions?.newScout
    );
};

export const buildUserPermissionSet = (
    tenant: AuthTenantDetails,
    token: AccessToken,
    metadata: Metadata,
    flags: LDFlagSet
): AuthPermissionSet => {
    const isAdminOrOwnerOrManager = token.AccountType && ['admin', 'owner', 'manager'].includes(token.AccountType);

    const canEditAdmin = token.User.canEditAdmin ?? isAdminOrOwnerOrManager;
    const canSeeFinance = token.User.canSeeFinancials ?? isAdminOrOwnerOrManager;
    const isPerformFocusedTenant = evaluatePerformFocusedTenant(metadata.mobileOptions);

    return {
        canEditGeneralConfiguration: booleanOrFalse(canEditAdmin),
        canEditJobs: booleanOrFalse(canEditAdmin && !isPerformFocusedTenant),
        canEditLots: booleanOrFalse(tenant.features.locationLots && canEditAdmin),
        canEditOrchardLocationV1: booleanOrFalse(tenant.features.locationV1 && canEditAdmin),
        canEditOrchardLocationV2: booleanOrFalse(tenant.features.locationV2 && canEditAdmin),
        canEditPayrollConfiguration: booleanOrFalse(
            tenant.features.payrollOvertime && canEditAdmin && !isPerformFocusedTenant
        ),
        canEditSectors: booleanOrFalse(tenant.features.locationSectors && canEditAdmin),
        canEditStaff: booleanOrFalse(canEditAdmin),
        canEditVarieties: booleanOrFalse(canEditAdmin),
        canEditTimesheet: booleanOrFalse(tenant.products.timesheets && !isPerformFocusedTenant && canSeeFinance),
        canManageGraderRuns: booleanOrFalse(
            flags[LDFeatureFlag.GraderRuns] &&
                tenant.products?.spectre &&
                (token.User?.spectreModule?.spectreOwner ||
                    token.User?.spectreModule?.spectreAdmin ||
                    token.User?.spectreModule?.spectreManager)
        ),
        canManagePayrun: booleanOrFalse(
            tenant.products.payroll && !isPerformFocusedTenant && canSeeFinance && isAdminOrOwnerOrManager
        ),
        canSeeAnalytics: booleanOrFalse(tenant.products.analytics && canSeeFinance),
        canSeeFruitQuality: booleanOrFalse(tenant.products?.packhouseAnalytics && isAdminOrOwnerOrManager),
        canSeeHarvest: booleanOrFalse(!isPerformFocusedTenant),
        canSeeInsights: booleanOrFalse(canSeeFinance && !isPerformFocusedTenant),
        canSeeLocation: booleanOrFalse(tenant.products.locationGis && isAdminOrOwnerOrManager),
        canSeeMaps: booleanOrFalse(!isPerformFocusedTenant),
        canSeeMyGrowers: booleanOrFalse(tenant.products.myGrowers && isAdminOrOwnerOrManager),
        canSeeNzApMapping: booleanOrFalse(tenant.features.locationNZAPIMapping && isAdminOrOwnerOrManager),
        canSeePayroll: booleanOrFalse(tenant.products.payroll && !isPerformFocusedTenant && canSeeFinance),
        canSeeReports: booleanOrFalse(tenant.products.reports && !isPerformFocusedTenant),
        canSeeScout: booleanOrFalse(!isPerformFocusedTenant),
        canSeeSpectre: booleanOrFalse(
            tenant.products?.spectre &&
                (token.User?.spectreModule?.spectreOwner ||
                    token.User?.spectreModule?.spectreAdmin ||
                    token.User?.spectreModule?.spectreManager)
        ),
        canSeeSpray: booleanOrFalse(tenant.products.spray && isAdminOrOwnerOrManager),
        canSeeTimesheets: booleanOrFalse(tenant.products.timesheets && !isPerformFocusedTenant && canEditAdmin),
    };
};

export const buildTenantProductSet = (metadata: Metadata, flags: LDFlagSet): AuthTenantProductSet => {
    return {
        analytics: booleanOrFalse(flags[LDFeatureFlag.Analytics]),
        locationGis: booleanOrFalse(flags[LDFeatureFlag.LocationGis]),
        myGrowers: booleanOrFalse(flags[LDFeatureFlag.MyGrowers]),
        packhouseAnalytics: booleanOrFalse(metadata.mobileOptions?.packhouseAnalytics),
        payroll: booleanOrFalse(metadata.mobileOptions?.newTimesheet),
        perform: booleanOrFalse(metadata.mobileOptions?.perform),
        reports: booleanOrFalse(flags[LDFeatureFlag.Reports]),
        spectre: booleanOrFalse(metadata.mobileOptions?.spectreGroup),
        spray: booleanOrFalse(flags[LDFeatureFlag.Spray]),
        timesheets: booleanOrFalse(metadata.mobileOptions?.newTimesheet),
    } as AuthTenantProductSet;
};

export const buildTenantFeatureSet = (metadata: Metadata, flags: LDFlagSet): AuthTenantFeatureSet => {
    return {
        locationV1: booleanOrFalse(!metadata.options.orchardLocationVersion2),
        locationV2: booleanOrFalse(metadata.options.orchardLocationVersion2),
        locationNZAPIMapping: booleanOrFalse(
            metadata.options.orchardLocationVersion2 && flags[LDFeatureFlag.NZAPIMappingTool]
        ),
        locationSectors: booleanOrFalse(metadata.options.orchardLocationVersion2 && metadata.options.hasSectors),
        locationLots: booleanOrFalse(metadata.options.orchardLocationVersion2 && metadata.options.hasLots),
        payrollOvertime: booleanOrFalse(metadata.options.enableOvertimeFeature),
        spectreColor: booleanOrFalse(metadata.remoteSettings?.isColorEnable),
        spectreColorAutomation: booleanOrFalse(metadata.remoteSettings?.isColorAutomationEnable),
        spectreColorGrade: booleanOrFalse(metadata.remoteSettings?.isColorGradeEnabled),
    } as AuthTenantFeatureSet;
};
