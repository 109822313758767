import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { History } from '@remix-run/router';
import { createBrowserHistory } from 'history';
import 'rc-time-picker/assets/index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
// Use unstable_HistoryRouter for Spectre router temporary
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { SuspenseLoading } from './components_v2/common/SuspenseLoading/SuspenseLoading';
import './index.scss';
import './main.css';
import './site.css';
import config from './config';
import configureStore from './store/configureStore';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ApiServiceInterceptor } from './ApiService';
import { AuthProvider } from './providers/AuthProvider';
import type {} from '@mui/x-data-grid/themeAugmentation';

// Create browser history to use in the Redux store
export const history = createBrowserHistory() as unknown as History;

(async () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    const store = configureStore(history, {});

    const theme = createTheme({
        palette: {
            primary: {
                light: '#DF1D00',
                main: '#C63D28',
                dark: '#9E3626',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
            error: {
                main: '#C63D28',
            },
        },
        typography: {
            fontFamily: ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        fontWeight: 500,
                        '&:hover': {
                            boxShadow: 'none',
                        },
                        textTransform: 'none',
                    },
                    outlined: {
                        backgroundColor: 'white',
                        borderColor: '#C63D28',
                        '&:hover': {
                            backgroundColor: '#EEEEEE',
                            borderColor: '#C63D28',
                        },
                    },
                    text: {
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: '#EEEEEE',
                        },
                        '&.black': {
                            color: '#212121',
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        display: 'table-cell',
                        verticalAlign: 'top',
                        paddingTop: '0',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '14px',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        overflow: 'unset',
                        '.MuiAutocomplete-option': {
                            background: 'white',
                            fontSize: '14px',
                            padding: 0,
                            '&[aria-selected="true"]': {
                                backgroundColor: 'rgba(228,231,235,.4)',
                            },
                        },
                    },
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        transform: 'none',
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'white',
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        marginTop: 'unset',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: 'unset',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: '#212121',
                        fontWeight: 400,
                        borderColor: '#9E9E9E',
                        backgroundColor: 'white',
                        '&:hover': {
                            borderColor: '#616161',
                        },
                        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#424242',
                        },
                        '.MuiIconButton-root': {
                            color: '#212121',
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: '#212121',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#616161',
                        '&.Mui-disabled': {
                            color: '#9E9E9E',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#EEEEEE',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#E0E0E0 !important',
                        },
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        color: '#424242',
                        borderRadius: '8px',
                        '.AlertDescription': {
                            fontSize: '14px',
                            marginTop: '3px',
                        },
                        '.MuiAlert-icon': {
                            paddingTop: '11.5px',
                            marginRight: '6px',
                            opacity: 'unset',
                            '.MuiSvgIcon-root': {
                                fontSize: '14px',
                            },
                        },
                    },
                },
                variants: [
                    {
                        props: { severity: 'success' },
                        style: {
                            backgroundColor: '#E6F2EC',
                            border: '1px solid #007D3E',
                            '.MuiAlert-icon': {
                                color: '#007D3E',
                            },
                        },
                    },
                    {
                        props: { severity: 'error' },
                        style: {
                            backgroundColor: '#F9E6E8',
                            border: '1px solid #CC071E',
                            '.MuiAlert-icon': {
                                color: '#CC071E',
                            },
                        },
                    },
                    {
                        props: { severity: 'warning' },
                        style: {
                            backgroundColor: '#FCEACA',
                            border: '1px solid #DF8D00',
                            '.MuiAlert-icon': {
                                color: '#DF8D00',
                            },
                        },
                    },
                    {
                        props: { severity: 'info' },
                        style: {
                            backgroundColor: '#F2F7FC',
                            border: '1px solid #0065CB',
                            '.MuiAlert-icon': {
                                color: '#0065CB',
                            },
                        },
                    },
                ],
            },
            MuiAlertTitle: {
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                        color: '#424242',
                        fontSize: '16px',
                        marginBottom: 'unset',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    },
                },
            },
        },
    });

    const LDProvider = await asyncWithLDProvider({
        clientSideID: config.LAUNCHDARKLY_CLIENT_SIDE_ID,
        reactOptions: {
            useCamelCaseFlagKeys: false,
        },
    });

    const queryClient = new QueryClient();

    root.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SuspenseLoading>
                    <BrowserRouter history={history}>
                        <LDProvider>
                            <AuthProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <QueryClientProvider client={queryClient}>
                                        <ApiServiceInterceptor>
                                            <App />
                                            <ReactQueryDevtools initialIsOpen={false} />
                                        </ApiServiceInterceptor>
                                    </QueryClientProvider>
                                </LocalizationProvider>
                            </AuthProvider>
                        </LDProvider>
                    </BrowserRouter>
                </SuspenseLoading>
            </ThemeProvider>
        </Provider>
    );

    navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        })
        .catch(function (error) {
            console.log('Service worker has errors: ', error);
        });
})();
