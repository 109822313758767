import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';

export const SuspenseLoading = ({ children }: { children: React.ReactNode }) => {
    return (
        <Suspense
            fallback={
                <Backdrop open={true} sx={theme => ({ backgroundColor: '#fff', zIndex: theme.zIndex.drawer + 1 })}>
                    <CircularProgress color="primary" />
                </Backdrop>
            }
        >
            {children}
        </Suspense>
    );
};
