import {
    addDays,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    getDay,
    getMonth,
    getYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
} from 'date-fns';
import getTime from 'date-fns/getTime';
import moment from 'moment-timezone';
import { notify } from 'src/components_v2/common/Notification';

const isDateFormatValidated = (date: string | number | Date) => {
    if (['string', 'number'].includes(typeof date)) {
        return !isNaN(Date.parse(date.toString()));
    }
    return true;
};

export const getFormattedDate = (date?: string | number | Date) => {
    return date && isDateFormatValidated(date) ? new Date(date) : new Date();
};

export const getFormattedDateWithTimezone = (timezone: string, date?: string | number | Date) => {
    if (typeof date === 'string' && date && isDateFormatValidated(date)) {
        const momentObj = moment.tz(date, timezone);

        const dateObj = momentObj.toDate();
        return dateObj;
    }

    return getFormattedDate(date);
};

export const getFormattedDateWithCurrentTimezone = (date?: string | number | Date, isUtc?: boolean) => {
    if (isUtc) {
        return new Date(moment(date).format());
    }
    return new Date(moment(date).tz('utc', false).format());
};

const formattedDate = (formatStandard: string, date?: Date | number) => {
    return format(date ?? getFormattedDate(), formatStandard);
};

export const getFormattedDayMonthYear = (date?: Date | number, isUtc?: boolean) => {
    if (isUtc) {
        return moment(date).format('DD MMM yyyy');
    }
    return moment(date).tz('utc', false).format('DD MMM yyyy');
};

export const getFormattedDayMonth = (date?: Date, isUtc?: boolean) => {
    if (isUtc) {
        return moment(date).format('DD MMM');
    }
    return moment(date).tz('utc', false).format('DD MMM');
};
export const getFormattedMonthDayYear = (date?: Date) => {
    return formattedDate('MM/dd/yyyy', date);
};

export const getFormattedHourMin = (date?: Date) => {
    return formattedDate('HH:mm', date);
};

export const getFormattedZeroHour = (date?: Date) => {
    return startOfDay(date ?? getFormattedDate());
};

export const getFormattedMidnight = (date?: Date) => {
    return endOfDay(date ?? getFormattedDate());
};

export const getStartDateOfWeekDiff = (startingDay: number, weekDiffer?: number): Date => {
    //startingDay : 1 => Monday 7 => Sunday
    var startDayOfWeek = 0;
    const currentDay = getDay(new Date());
    const daysDiff = currentDay - startingDay;
    if (daysDiff < 0) startDayOfWeek = 7 + daysDiff;
    if (daysDiff > 0) startDayOfWeek = daysDiff;
    return subDays(new Date(), startDayOfWeek + (weekDiffer ?? 0) * 7);
};

export const getFirstDayOfWeek = (date?: Date) => {
    return addDays(startOfWeek(date ?? getFormattedDate()), 1);
};

export const getLastDayOfWeek = (date?: Date) => {
    return addDays(endOfWeek(date ?? getFormattedDate()), 1);
};

export const getPreviousDay = (daysDiffer?: number, date?: Date) => {
    return daysDiffer ? subDays(getFormattedDate(date), daysDiffer) : addDays(subMonths(getFormattedDate(date), 1), 1);
};

export const getFutureDay = (daysDiffer: number, date?: Date) => {
    return date ? addDays(new Date(date), daysDiffer) : addDays(new Date(), daysDiffer);
};

export const getFirstDayOfMonth = (date?: Date) => {
    return startOfMonth(date ?? getFormattedDate());
};

export const getLastDayOfMonth = (date?: Date) => {
    return endOfMonth(date ?? getFormattedDate());
};

export const getFirstDayOfYear = (date?: Date) => {
    return startOfYear(date ?? getFormattedDate());
};

export const getLastDayOfYear = (date?: Date) => {
    return endOfYear(date ?? getFormattedDate());
};

export const getCurrentYear = (date?: Date) => {
    return getYear(date ?? getFormattedDate());
};

export const getFirstDayOfEarlistYear = (trackBackYears?: number, date?: Date) => {
    const earlistYear = getYear(date ?? getFormattedDate()) - (trackBackYears ?? 10);
    return getFirstDayOfYear(new Date(`01/01/${earlistYear}`));
};

export const getCurrentMonth = (date?: Date) => {
    return getMonth(date ?? new Date());
};

// Convert hours from 6.75 = 6:45
export const formatTime = (time: number) => {
    try {
        const now = format(new Date(), 'yyyy/MM/dd');
        const custTime = addZero(Math.floor(time)) + ':' + addZero(60 * (time % 1)) + ':00';
        const custDt = new Date(`${now} ${custTime}`);
        return getFormattedHourMin(custDt);
    } catch (err) {
        return '';
    }
};

const addZero = (number: number): string => {
    return number < 10 ? '0' + number : `${number}`;
};

export const getTimeStamp = (date: Date) => {
    return Math.round(getTime(date) / 1000);
};

export const getFormattedYearMonthDay = (date?: Date) => {
    return formattedDate('yyyy-MM-dd', date);
};

const getFormattedWeekMonthDayYear = (date: Date) => {
    return date.toDateString();
};

export const formatNumberToDate = (dateNumber: number, formatWithWeek?: true) => {
    const date = new Date(dateNumber * 1000);
    return formatWithWeek ? getFormattedWeekMonthDayYear(date) : getFormattedYearMonthDay(date);
};

export const isDateBetweenRange = (date: Date, range: { startDate: Date; endDate: Date }) => {
    // Remove time from date
    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const dateTimeStamp = getTimeStamp(dateWithoutTime);
    const startDateTimeStamp = getTimeStamp(range.startDate);
    const endDateTimeStamp = getTimeStamp(range.endDate);
    return dateTimeStamp >= startDateTimeStamp && dateTimeStamp <= endDateTimeStamp;
};

export const compareDates = (date1: Date, date2: Date) => {
    return getTimeStamp(date1) - getTimeStamp(date2);
};

export const getDayOfDate = (date: Date): number => {
    return getDay(date) > 0 ? getDay(date) : 7;
};

export const getDiffDays = (range: { startDate: Date; endDate: Date }) => {
    const diffTime = compareDates(range.endDate, range.startDate);
    return diffTime > 0 ? Math.ceil(diffTime / 86400) : 0;
};

export const getMaxDate = (dates: Date[]) => {
    return dates.reduce((maxDate, date) => (maxDate > date ? maxDate : date));
};

export const formatDuration = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0) {
        return `${minutes}m`;
    } else if (minutes === 0) {
        return `${hours}h`;
    } else {
        return `${hours}h ${minutes}m`;
    }
};

/**
 * Validates the selected period to ensure it is within acceptable limits.
 * Generates a warning message if the selected period is invalid or exceeds the limit.
 * @returns {boolean} True if the selected period is valid and within the limit, false otherwise.
 */
export const validateMaximumSelectedPeriod = (
    startDate: Date | string,
    endDate: Date | string,
    maxDays: number = 365
): boolean => {
    // Check if start and end dates are selected
    if (!startDate || !endDate) {
        notify({ message: 'A valid period must be selected', type: 'warning', autoClose: 10000 });
        return false;
    }

    // If the difference is more than 1 year, don't call the API
    const daysDiff = moment(endDate).diff(moment(startDate), 'days');
    if (daysDiff > maxDays) {
        notify({ message: `Selected period cannot exceed ${maxDays} days`, type: 'warning', autoClose: 10000 });
        return false;
    }

    return true;
};
