import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiCollection, defaultApiClient, selectApiClient } from '.';

export const getData = async <T extends unknown>(
    endpoint: string,
    api: ApiCollection = ApiCollection.Default
): Promise<T> => {
    const client: AxiosInstance = selectApiClient(api);
    const response = await client.get<T>(endpoint);
    return response.data;
};

export const postData = async <T extends unknown>(
    endpoint: string,
    data?: any,
    api: ApiCollection = ApiCollection.Default,
    config?: AxiosRequestConfig
): Promise<T> => {
    const client: AxiosInstance = selectApiClient(api);
    const response = await client.post<T>(endpoint, data, { ...config });
    return response.data;
};

export const deleteData = async <T extends unknown>(
    endpoint: string,
    data?: any,
    api: ApiCollection = ApiCollection.Default
): Promise<T> => {
    const config = data ? { data } : undefined;
    const client: AxiosInstance = selectApiClient(api);
    const response = await client.delete<T>(endpoint, config);
    return response.data;
};

export const putData = async <T extends unknown>(
    endpoint: string,
    data: any,
    api: ApiCollection = ApiCollection.Default
): Promise<T> => {
    const client: AxiosInstance = selectApiClient(api);
    const response = await client.put<T>(endpoint, data);
    return response.data;
};

export const patchData = async <T extends unknown>(
    endpoint: string,
    data: any,
    api: ApiCollection = ApiCollection.Default
): Promise<T> => {
    const client: AxiosInstance = selectApiClient(api);
    const response = await client.patch<T>(endpoint, data);
    return response.data;
};

export const downloadFile = async <T extends unknown>(
    endpoint: string,
    fileName: string,
    postPayload: T
): Promise<boolean> => {
    return defaultApiClient
        .post(endpoint, postPayload, { responseType: 'blob' })
        .then((response: any) => {
            const file = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(file);

            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            return true;
        })
        .catch(() => {
            return false;
        });
};
