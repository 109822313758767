export const MenuUrls = {
    HOME: '/',
    HARVEST: '/',
    ADMIN_CONTRACTORS: '/admin/contractors',
    ADMIN_DEFAULTS: '/admin/defaults',
    ADMIN_DEFECTS: '/admin/qc-defects',
    ADMIN_EXPORT_COUNTRIES: '/admin/export-countries',
    ADMIN_GENERAL: '/admin/general',
    ADMIN_GENERAL_DEFAULTS: '/admin/general-defaults',
    ADMIN_GRADER_RUNS: '/admin/grader-runs',
    ADMIN_GRADER_RUNS_NEW: '/admin/grader-runs/new',
    ADMIN_GRADER_RUNS_EDIT: '/admin/grader-runs/:id/edit',
    ADMIN_GRADER_RUNS_RESULTS: '/admin/grader-runs/:id/results',
    ADMIN_JOBS: '/admin/jobs',
    ADMIN_LOTS: '/admin/lots',
    ADMIN_ORCHARDS: '/admin/orchards',
    ADMIN_PAYROLL: '/admin/payroll',
    ADMIN_PICKS: '/admin/picks',
    ADMIN_QUALITY: '/admin/qualities',
    ADMIN_RANCHES: '/admin/ranches',
    ADMIN_RATES: '/admin/rates',
    ADMIN_SECTORS: '/admin/sectors',
    ADMIN_STAFF: '/admin/staff',
    ADMIN_STAFF_FORM: '/admin/staff-form',
    ADMIN_TASKS: '/admin/tasks',
    ADMIN_TREATMENT: '/admin/treatments',
    ADMIN_UNITS: '/admin/units',
    ADMIN_VARIETIES: '/admin/varieties',
    ANALYTICS: '/analytics/orchards',
    FORGOT_PASSWORD: '/forgot-password',
    FRUIT_QUALITY: '/analytics/fruit-quality',
    INSIGHTS: '/insights',
    LOCATION: '/location',
    LOGIN: '/not-logged-in',
    MAPS: '/maps',
    MY_GROWERS: '/my-growers',
    PAYROLL: '/payroll',
    PAYROLL_UNASSIGNED_ITEMS: '/payroll/unassigned-items',
    PAYROLL_DETAILS: '/payroll/details',
    QUALITY_CONTROLS: '/quality-controls',
    SCOUT: '/scout',
    SPECTRE: '/spectre/groups',
    SPRAY: '/spray',
    TIMESHEETS: '/timesheets',
    REPORTS: '/reports',
    RESET_PASSWORD: '/reset-password',
};
