import { getIdTokenAsString } from 'src/helpers/auth';
import { ApiCollection } from '.';
import { StorageKey, getLocalStorageItem } from 'src/helpers/storage';
import { handleRefreshToken } from './refreshToken';
import axios, { AxiosInstance } from 'axios';
import { AuthDetails } from 'src/models/auth';
import { ApiEndpoint } from './ApiEndpoint';

let globalCancelTokenSource = axios.CancelToken.source();

export const requestInterceptor = async (auth: AuthDetails, config: any, api: ApiCollection) => {
    // Set the Authorization header based on the API
    config.headers['Authorization'] = `Bearer ${await getAuthTokenForRequest(auth, api, config)}`;

    // Set the global cancel token for all requests
    config.cancelToken = globalCancelTokenSource.token;

    return config;
};

export const errorInterceptor = (auth: AuthDetails, error: any, api: ApiCollection, apiClient: AxiosInstance) => {
    console.error(error);
    return handleRefreshToken(auth, error, api, apiClient);
};

export const responseInterceptor = (response: any) => response;

export const cancelPendingRequests = () => {
    // Cancel all requests using the global cancel token
    globalCancelTokenSource.cancel('Canceling all pending requests');

    // Reinitialize the cancel token source for future requests
    globalCancelTokenSource = axios.CancelToken.source();
};

const getAuthTokenForRequest = async (auth: AuthDetails, api: ApiCollection, config: any) => {
    if (config.url === ApiEndpoint.RefreshToken) {
        return getLocalStorageItem(StorageKey.RefreshToken, true);
    }

    switch (api) {
        case ApiCollection.PayrollService:
            return await getIdTokenAsString(auth, true);
        default:
            return getLocalStorageItem(StorageKey.Token, true);
    }
};
