import { isBoolean } from 'lodash';
import { notify } from 'src/components_v2/common/Notification';
import { NotificationMessage } from 'src/DataContracts/NotificationMessage';
import { getIdTokenAsString } from './auth';
import { getDefaultProperties } from 'src/hooks/userBehavioural/useBehaviouralEvent';
import { AuthDetails } from 'src/models/auth';

export const HANDSHAKE_MESSAGE_TYPE = 'HANDSHAKE';
export const CHANNEL_INIT_MESSAGE_TYPE = 'CHANNEL_INIT';
export const TOKEN_REQUEST_MESSAGE_TYPE = 'TOKEN_REQUEST';
export const TOKEN_EXCHANGE_MESSAGE_TYPE = 'TOKEN_EXCHANGE';
export const BEHAVIOURAL_EVENT_PROPERTIES_REQUEST_MESSAGE_TYPE = 'BEHAVIOURAL_EVENT_PROPERTIES_REQUEST';
export const BEHAVIOURAL_EVENT_PROPERTIES_EXCHANGE_MESSAGE_TYPE = 'BEHAVIOURAL_EVENT_PROPERTIES_EXCHANGE';
export const STAFF_ID_REQUEST_MESSAGE_TYPE = 'STAFF_ID_REQUEST';
export const STAFF_ID_EXCHANGE_MESSAGE_TYPE = 'STAFF_ID_EXCHANGE';
export const CLOSE_OPERATOR_MODAL_IN_HOST_REQUEST_MESSAGE_TYPE = 'CLOSE_OPERATOR_MODAL_IN_HOST_REQUEST';
export const PARENT_FULL_SCREEN_REQUEST_MESSAGE_TYPE = 'FULL_SCREEN_REQUEST';
export const OPEN_TRUSTED_URI_REQUEST_MESSAGE_TYPE = 'OPEN_TRUSTED_URI_REQUEST';

export interface CrossPlatformMessageExchangeProps {
    iframeRef: React.MutableRefObject<any>;
    authDetails: AuthDetails;
    channel: MessageChannel | null;
    message: MessageEvent<any>;
    staffId?: string;
    handleClose?: (success?: boolean) => void;
    setIsFullScreen?: (fullScreen: boolean) => void;
}

/**
 * Handler to attach using `window.addEventListener(...)` to the page that will communicate with a cross-platform iFrame.
 *
 * @param props
 */
export function handleCrossPlatformWindowMessageEvents(props: CrossPlatformMessageExchangeProps): void {
    if (props.iframeRef && props.iframeRef.current && props.message.source === props.iframeRef.current.contentWindow) {
        parseMessage(props);
    }
}

async function parseMessage(props: CrossPlatformMessageExchangeProps): Promise<void> {
    try {
        // Note that all messages sent to the Host app need to be JSON-encoded. Same applies to messages sent back.
        const message = JSON.parse(props.message.data);

        if (!message.messageType) {
            throw new Error('Unable to read the MessageType from the incoming message.');
        }

        switch (message.messageType) {
            case HANDSHAKE_MESSAGE_TYPE:
                handshake(props);
                break;
            case TOKEN_REQUEST_MESSAGE_TYPE:
                const idToken = await getIdTokenAsString(props.authDetails, true);

                const tokenExchangeMessageData = {
                    messageType: TOKEN_EXCHANGE_MESSAGE_TYPE,
                    token: idToken,
                };

                sendMessageToMessageChannel(props.channel, tokenExchangeMessageData);
                break;
            case BEHAVIOURAL_EVENT_PROPERTIES_REQUEST_MESSAGE_TYPE:
                // The module property will be set inside the app requesting the data
                const defaultProperties = getDefaultProperties();

                const behaviouralEventPropertiesExchangeMessageData = {
                    messageType: BEHAVIOURAL_EVENT_PROPERTIES_EXCHANGE_MESSAGE_TYPE,
                    ...defaultProperties,
                };

                sendMessageToMessageChannel(props.channel, behaviouralEventPropertiesExchangeMessageData);
                break;
            case STAFF_ID_REQUEST_MESSAGE_TYPE:
                if (props.staffId && props.staffId.length) {
                    const staffExchangeMessageData = {
                        messageType: STAFF_ID_EXCHANGE_MESSAGE_TYPE,
                        staffId: props.staffId,
                    };
                    sendMessageToMessageChannel(props.channel, staffExchangeMessageData);
                } else {
                    throw new Error('Unable to retrieve the Staff');
                }
                break;
            case CLOSE_OPERATOR_MODAL_IN_HOST_REQUEST_MESSAGE_TYPE:
                let success: boolean | undefined =
                    message.success && isBoolean(message.success) ? message.success : undefined;
                props.handleClose && props.handleClose(success);
                break;
            case PARENT_FULL_SCREEN_REQUEST_MESSAGE_TYPE:
                if (message.fullScreen === undefined || !isBoolean(message.fullScreen)) {
                    throw new Error("The parameter 'fullScreen' is invalid, or missing from the request");
                }
                props.setIsFullScreen && props.setIsFullScreen(message.fullScreen);
                break;
            case OPEN_TRUSTED_URI_REQUEST_MESSAGE_TYPE:
                if (message.uri === undefined) {
                    throw new Error("The parameter 'uri' is invalid, or missing from the request");
                }
                window.open(message.uri, '_blank');
                break;
            default:
                throw new Error(`Unsupported message type received: ${message.messageType}`);
        }
    } catch (error) {
        console.error(error);
        notify({ message: NotificationMessage.CrossPlatformMessageExchangeError, type: 'error' });
    }
}

function handshake(props: CrossPlatformMessageExchangeProps): void {
    // Always create a new MessageChannel on the 'HANDSHAKE' event.
    // Otherwise, if the iframe reloads, the previously created channel would fail to assign ports again.
    props.channel = new MessageChannel();

    props.channel.port1.onmessage = message => {
        if (message.data) {
            parseMessage({
                message: message,
                channel: props.channel,
                iframeRef: props.iframeRef,
                authDetails: props.authDetails,
                staffId: props.staffId,
                handleClose: props.handleClose,
                setIsFullScreen: props.setIsFullScreen,
            });
        }
    };

    props.iframeRef.current.contentWindow.postMessage(
        JSON.stringify({ messageType: CHANNEL_INIT_MESSAGE_TYPE, hostType: 'OMS' }),
        '*',
        [props.channel.port2]
    );
}

function sendMessageToMessageChannel(channel: MessageChannel | null, message: any): void {
    if (!channel) {
        throw new Error('MessageChannel not initialized ("handshake" not performed)!');
    }
    channel.port1.postMessage(JSON.stringify(message));
}
