import { createContext, useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getData, postData } from 'src/ApiService/httpMethods';
import { ApiEndpoint, FrontendPath } from 'src/ApiService/ApiEndpoint';
import { Metadata, MetadataWithLocalizations } from 'src/DataContracts/Metadata';
import { GenericResponse } from 'src/DataContracts/GenericResponse';
import { StorageKey, clearOwnedLocalStorage, getLocalStorageItem, setLocalStorageItem } from 'src/helpers/storage';
import { notify } from 'src/components_v2/common/Notification';
import { NotificationMessage } from 'src/DataContracts/NotificationMessage';
import {
    buildTenantFeatureSet,
    buildTenantProductSet,
    buildUserPermissionSet,
    parseJwtToken,
    setTokenOnLocalStorage,
} from 'src/helpers/auth';
import {
    LoginDetails,
    AccessToken,
    AuthUserDetails,
    AuthTenantDetails,
    SwitchTenantResponse,
    AuthDetails,
} from 'src/models/auth';
import { ApiCollection } from 'src/ApiService';
import { useDispatch } from 'react-redux';
import { convertJsonToFormData } from 'src/helpers/form';
import { cancelPendingRequests } from 'src/ApiService/interceptorHandlers';
import { getErrorMessage } from 'src/helpers/errors';
import lodash from 'lodash';

export const AuthContext = createContext<AuthDetails | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [tokenDetails, setTokenDetails] = useState<AccessToken | undefined | null>(undefined);
    const [metadataDetails, setMetadataDetails] = useState<Metadata | undefined | null>(undefined);

    const flags = useFlags();

    // Deprecated, but still needed as js files are still using redux
    const dispatch = useDispatch();

    // Initialize auth with the token from local storage
    useEffect(() => {
        const initializeAuth = async () => {
            console.log('initializeAuth');
            const token = getLocalStorageItem(StorageKey.Token, true);

            if (token) {
                await _setAuth(token);
                return;
            }

            setTokenDetails(null);
            setMetadataDetails(null);
        };

        initializeAuth();
    }, []);

    // Listen for changes in local storage (from another tab)
    useEffect(() => {
        const handleLocalStorageChange = async (event: StorageEvent) => {
            if (event.key !== StorageKey.Token) {
                return;
            }

            if (event.newValue) {
                await _setAuth(event.newValue);
                return;
            }

            _clearAuth();
        };

        window.addEventListener('storage', handleLocalStorageChange);
        return () => window.removeEventListener('storage', handleLocalStorageChange);
    }, []);

    const login = async (details: LoginDetails): Promise<boolean> => {
        const formData = `Email=${encodeURIComponent(details.email)}&Password=${encodeURIComponent(details.password)}`;
        const response = await postData<GenericResponse<object>>(ApiEndpoint.Login, formData, ApiCollection.Default, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        if (response.code === 0 && response.message?.content != null) {
            await _setAuth(response.message.content, true);
            return true;
        }

        return false;
    };

    const logout = async (): Promise<any> => {
        await postData(ApiEndpoint.Logout);
        cancelPendingRequests();
        await _clearAuth();
    };

    const refreshToken = async (): Promise<string> => {
        try {
            const response = await getData<GenericResponse<object>>(ApiEndpoint.RefreshToken);

            const newToken = response.message?.content;
            if (!newToken || lodash.isEmpty(newToken)) {
                throw new Error('Could not obtain a new token');
            }

            // Covers the case where the refresh could potentially run while the user is logging out
            const token = getLocalStorageItem(StorageKey.Token, true);
            const refreshToken = getLocalStorageItem(StorageKey.RefreshToken, true);
            if (!token || !refreshToken) {
                throw new Error('Could not find the current token or refresh token in local storage');
            }

            await _setAuth(newToken, true);

            return newToken;
        } catch (error) {
            throw new Error(`Could not refresh the token: ${getErrorMessage(error)}`);
        }
    };

    const reloadMetadata = async (): Promise<void> => {
        try {
            const response = await getData<GenericResponse<Metadata>>(ApiEndpoint.Metadata, ApiCollection.Default);
            const metadataResponse = response.response;
            if (metadataResponse.user.canLogin === false) {
                throw new Error('User is not allowed to login (metadata details)');
            }

            setMetadataDetails(metadataResponse);
            setLocalStorageItem(StorageKey.MetaData, metadataResponse);

            let metadataWithLocalizations: MetadataWithLocalizations = metadataResponse;
            if (metadataResponse.localisation) {
                try {
                    const localizationResponse = await getData<any>(
                        FrontendPath.Localization + '/' + metadataResponse.localisation + '.json',
                        ApiCollection.Frontend
                    );
                    metadataWithLocalizations.localisations = localizationResponse;
                } catch {
                    console.log(`Error fetching localization json for '${metadataResponse.localisation}'`);
                }
            }

            // Deprecated, still needed as js files are still using redux
            dispatch({ type: 'RECEIVE_METADATA', metadata: metadataWithLocalizations });
        } catch (error) {
            console.log('Error fetching metadata', error);
            notify({ message: NotificationMessage.MetadataErrorMessage, type: 'error' });
        }
    };

    const switchTenant = async (newTenantId: string) => {
        try {
            await postData<GenericResponse<SwitchTenantResponse>>(
                ApiEndpoint.SwitchOrchard,
                convertJsonToFormData({
                    Value: newTenantId,
                })
            ).then(async res => {
                if (res.code !== 0 || !res.response?.token) {
                    throw new Error();
                }

                await _setAuth(res.response.token, true);
                window.location.reload();
            });
        } catch (e) {
            notify({ message: NotificationMessage.SwitchOrchardFailed, type: 'error' });
        }
    };

    const _setAuth = async (token: string, forceMetadataReload: boolean = false): Promise<void> => {
        try {
            setTokenOnLocalStorage(token);

            const updatedToken = parseJwtToken(token);
            setTokenDetails(updatedToken);
            if (updatedToken.User.canLogin === false) {
                throw new Error('User is not allowed to login (token details)');
            }

            await _loadAndSetMetadataDetails(forceMetadataReload);
        } catch (error) {
            console.log('Error setting auth state: ', error);
            _clearAuth();
            throw error;
        }
    };

    const _clearAuth = async () => {
        clearOwnedLocalStorage();
        setTokenDetails(null);
        setMetadataDetails(null);
    };

    const _loadAndSetMetadataDetails = async (forceReload: boolean = false): Promise<void> => {
        const localStorageMetadata = getLocalStorageItem(StorageKey.MetaData);
        if (forceReload || !localStorageMetadata) {
            await reloadMetadata();
            return;
        }

        // Use the local storage metadata and refresh it in the background
        setMetadataDetails(localStorageMetadata);
        reloadMetadata();
    };

    const value = useMemo(() => {
        const isInitializingAuth = tokenDetails === undefined || metadataDetails === undefined;
        const isAuthenticated = isInitializingAuth ? undefined : !!(tokenDetails && metadataDetails);

        var tenantDetails = null;
        var userDetails = null;

        if (isAuthenticated) {
            tenantDetails = {
                products: buildTenantProductSet(metadataDetails as Metadata, flags),
                features: buildTenantFeatureSet(metadataDetails as Metadata, flags),
                defaults: metadataDetails?.defaults,
            } as AuthTenantDetails;

            userDetails = {
                id: tokenDetails?.Auth?.User?.localId,
                email: tokenDetails?.User.email,
                firstName: tokenDetails?.User.firstName,
                lastName: tokenDetails?.User.lastName,
                accountType: tokenDetails?.User.accountType,
                tenantId: tokenDetails?.User.orchardID,
                profileImageURL: metadataDetails?.user.profileImageURL,
                permissions: buildUserPermissionSet(
                    tenantDetails,
                    tokenDetails as AccessToken,
                    metadataDetails as Metadata,
                    flags
                ),
            } as AuthUserDetails;
        }

        return {
            isAuthenticated,
            user: userDetails,
            tenant: tenantDetails,
            analyticsToken: tokenDetails?.Auth?.analyticsToken,
            login,
            logout,
            refreshToken,
            reloadMetadata,
            switchTenant,
        };
    }, [tokenDetails, metadataDetails, flags]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
