export enum StorageKey {
    SideMenuOpen = 'side-menu-open',
    Token = 'token',
    RefreshToken = 'refresh_token',
    ActiveSubLink = 'activeSubLink',
    ExpandSubMenu = 'expandSubMenu',
    PayrollFilter = 'payroll-filter',
    TimesheetFilter = 'timesheet-filter',
    MetaData = 'meta-data',
    PayrollLocking = 'payroll-locking',
    TimesheetTable = 'timesheet-table',
    PayrollTable = 'payroll-table',
    NZAPMappingData = 'NZAPMappingData',
    NZAPProdSiteOptions = 'NZAPProdSiteOptions',
    NZAPMgmtAreaOptions = 'NZAPMgmtAreaOptions',
    DateLabelName = 'date-label-name',
    HarvestDateLabelName = '/-date-label-name',
    AdminGeneralDefaultsDateLabelName = '/admin/general-defaults-date-label-name',
    AdminStaffFormDateLabelName = '/admin/staff-form/-date-label-name',
    InsightsDateLabelName = '/insights-date-label-name',
    MapsDateLabelName = '/maps-date-label-name',
    PayrollDateLabelName = '/payroll-date-label-name',
    ScoutDateLabelName = '/scout-date-label-name',
    TimesheetsDateLabelName = '/timesheets-date-label-name',
}

// Only used to clear deprecated storage keys
enum DeprecatedStorageKey {
    AuthDetail = 'auth-detail',
    OvertimeFeatureFlag = 'overtimeFlag',
    CreateSprayPlanDraft = 'create-spray-plan',
}

export const setLocalStorageItem = (name: string, value: any, isKeepType?: boolean) => {
    localStorage.setItem(name, isKeepType ? value : JSON.stringify(value));
};

export const getLocalStorageItem = <T>(name: string, isKeepType?: boolean) => {
    const value = localStorage.getItem(name);
    return value ? (isKeepType ? value : JSON.parse(value)) : (null as T);
};

export const clearLocalStorageItem = (name: string) => {
    localStorage.removeItem(name);
};

export const clearOwnedLocalStorage = () => getOwnedLocalStorageKeys().forEach(key => clearLocalStorageItem(key));

export const getOwnedLocalStorageKeys = () => {
    const currentKeys = Object.values(StorageKey);
    const deprecatedKeys = Object.values(DeprecatedStorageKey);

    return [...currentKeys, ...deprecatedKeys];
};
