interface ErrorWithMessage {
    message: string;
}

/**
 * "Type predicate" (i.e. type guard) function that returns if whatever input was passed (of type `unknown`) is **any** object
 * that contains a `message` field (of type string). If it does, then it tells TypeScript that this input will going forward
 * be of type `ErrorWithMessage` (a simple interface containing just the `message` field), regardless of the original type.
 *
 * @param error Any object that may or may not have a `message` field. Standard `Error` instances always will, custom errors may not.
 * @returns
 */
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>)['message'] === 'string'
    );
}

/**
 * Checks if the passed `error` (of type `unknown`) contains a `message` field and returns the type-safe `ErrorWithMessage`
 * representation of that object if it does, otherwise will just cast the `error` to `String` and return whatever the `toString()`
 * outcome of the original class is, within the `message` field.
 *
 * @param error
 * @returns The return is guaranteed to contain a `message` field with the error description.
 */
function convertAnyToErrorWithMessage(error: unknown): ErrorWithMessage {
    if (isErrorWithMessage(error)) {
        return error;
    }

    return {
        message: String(error),
    };
}

/**
 * Extract and return the message from the error (which can be of any type - standard or custom).
 *
 * If custom errors are used in the project, make sure that the helper `convertAnyToErrorWithMessage` takes them into account, as
 * otherwise, if the custom error does not have a `message` field, just a default `toString()` representation of the custom
 * class will be used as the message.
 *
 * @param error
 * @returns
 */
export function getErrorMessage(error: unknown): string {
    return convertAnyToErrorWithMessage(error).message;
}
