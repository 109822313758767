import Admin from './Admin';
import { useLocation } from 'react-router-dom';

const AdminLink = () => {
    const location = useLocation();
    let mode = '';
    switch (location.pathname) {
        case '/admin/jobs':
            mode = 'activities';
            break;
        case '/admin/ranches':
            mode = 'sites';
            break;
        case '/admin/general':
            mode = 'leaves';
            break;
        default:
    }

    return <Admin modeFromUrl={mode} />;
};

export default AdminLink;
