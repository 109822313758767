function isJsonString(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}

const BEACONSUGGEST = isJsonString(window._env_?.BEACONSUGGEST);

const config = {
    BACKEND_API_URL: window._env_?.BACKEND_API_URL,
    BEACONID: window._env_?.BEACONID,
    BEACONSUGGEST: BEACONSUGGEST ? new Map(Object.entries(BEACONSUGGEST)) : null,
    ENVIRONMENT: window._env_?.ENVIRONMENT,
    LAUNCHDARKLY_CLIENT_SIDE_ID: window._env_?.LAUNCHDARKLY_CLIENT_SIDE_ID,
    LOCATION_MODULE_ROOT_URL: window._env_?.LOCATION_MODULE_ROOT_URL,
    MY_GROWERS_MODULE_ROOT_URL: window._env_?.MY_GROWERS_MODULE_ROOT_URL,
    PAYROLL_SERVICE_API_BASE_URL: window._env_?.PAYROLL_SERVICE_API_BASE_URL,
    SPECTRE_API_V3_URL: window._env_?.SPECTRE_API_V3_URL,
    SPECTRE_DASHBOARD_API_URL: window._env_?.SPECTRE_DASHBOARD_API_URL,
    SPRAY_MODULE_ROOT_URL: window._env_?.SPRAY_MODULE_ROOT_URL,
    USER_BEHAVIOURAL_ANALYTICS_PROJECT_TOKEN: window._env_?.USER_BEHAVIOURAL_ANALYTICS_PROJECT_TOKEN,
};

export default config;
