import { Navigate } from 'react-router-dom';
import { MenuUrls } from 'src/helpers/navigation';
import { useAuth } from 'src/hooks/useAuth';
import { AuthPermissionSet } from 'src/models/auth';

// Ensure that the user has the correct permissions to view the page
export const AuthenticatedRoute = ({ canSee, children }: { canSee: boolean | undefined; children: JSX.Element }) => {
    const auth = useAuth();

    // User is not authenticated, redirect them to the login page
    if (auth.isAuthenticated === false) {
        return <Navigate to={MenuUrls.LOGIN} replace />;
    }

    // User is authenticated but does not have the correct permissions
    // Redirect them to the first available page
    if (!canSee) {
        return <Navigate to={getFirstAvailablePage(auth.user?.permissions)} replace />;
    }

    // User is authenticated and has the correct permissions
    return children;
};

export const NotAuthenticatedRoute = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();

    // User is authenticated, redirect them to the first available page
    if (auth.isAuthenticated) {
        return <Navigate to={getFirstAvailablePage(auth.user?.permissions)} replace />;
    }

    // User is not authenticated
    return children;
};

export const getFirstAvailablePage = (permissions: AuthPermissionSet | null | undefined): string => {
    // Redirect to the page that the user was trying to access before logging in
    const redirectToIndex = location.search.indexOf('redirectTo');
    if (redirectToIndex !== -1) {
        const redirectTo = location.search.substring(redirectToIndex + 'redirectTo='.length);
        for (const url of Object.values(MenuUrls)) {
            if (redirectTo.includes(url) && url !== MenuUrls.HARVEST) {
                return redirectTo;
            }
        }
    }

    // Redirect to the first available page
    if (permissions?.canSeeHarvest) return MenuUrls.HARVEST;
    if (permissions?.canSeePayroll) return MenuUrls.PAYROLL;
    if (permissions?.canSeeTimesheets) return MenuUrls.TIMESHEETS;
    if (permissions?.canSeeMyGrowers) return MenuUrls.MY_GROWERS;
    if (permissions?.canSeeSpray) return MenuUrls.SPRAY;
    if (permissions?.canSeeSpectre) return MenuUrls.SPECTRE;
    if (permissions?.canEditStaff) return MenuUrls.ADMIN_STAFF;

    return MenuUrls.HOME;
};
