import mixpanel from 'mixpanel-browser';
import config from 'src/config';
import { DefaultEventSchema, EventProps } from './event.interface';
import { getIdToken } from 'src/helpers/auth';
import { getErrorMessage } from 'src/helpers/errors';

const integrationToken = config.USER_BEHAVIOURAL_ANALYTICS_PROJECT_TOKEN;
const token = integrationToken && integrationToken.length > 0 ? integrationToken : undefined;

const initMixpanel = () => {
    const isTestEnv = process.env['NODE_ENV'] !== 'production';
    if (token) {
        mixpanel.init(token, { debug: isTestEnv });
    }
};

initMixpanel();

const isSupportAccount = (email?: string) => {
    if (email) {
        return email.startsWith('support+') && email.endsWith('@hectre.com');
    }
    return false;
};

/**
 * These properties are used when sending events from within OMS but also from sub-modules (e.g. Spray)
 */
export function getDefaultProperties(): DefaultEventSchema {
    const idToken = getIdToken();
    const supportAccount = isSupportAccount(idToken.email);
    const distinctId = idToken.hashedUserId;

    return {
        distinct_id: distinctId,
        orchard_id: idToken.orchardID, // Should be discontinued in the future and `tenant_id` used instead (supplied below)
        tenant_id: idToken.orchardID,
        is_support_account: supportAccount,
        product_type: 'OMS',
    };
}

export const useBehaviouralEvent = () => {
    const sendEvent = <T>(props: EventProps<T>): void => {
        try {
            if (mixpanel && token) {
                const defaultProperties = getDefaultProperties();
                defaultProperties.module = props.module;

                mixpanel.identify(defaultProperties.distinct_id);
                mixpanel.track(props.name, {
                    ...defaultProperties,
                    ...props.extraProperties,
                });
            }
        } catch (error: any) {
            // Never interrupt the user flow for a failed behavioural event capture, only show a log in the console
            console.error(`Error capturing event: ${getErrorMessage(error)}`);
        }
    };

    return { sendEvent };
};
