import * as $ from 'jquery';
import config from '../config';
import { notify } from 'src/components_v2/common/Notification';
import { NotificationMessage } from 'src/DataContracts/NotificationMessage';
import { convertJsonToFormData } from 'src/helpers/form';
import { getData } from 'src/ApiService/httpMethods';
import { ApiEndpoint } from 'src/ApiService/ApiEndpoint';
import { defaultApiClient } from 'src/ApiService';

export class Utils {
    static filterNumber(num) {
        // filter not digit or dot
        num = num.replace(/[^\d\.]/g, '');
        // must start with digit not dot
        num = num.replace(/^\./g, '');
        // max one 0 at begining
        num = num.replace(/^0{2,}/, '0');
        // start with 0 must follow with decimal
        num = num.replace(/^0[\d]/, '0');
        // max one decimal allowed
        num = num.replace(/\.{2,}/g, '.');
        num = num.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        return num;
    }

    static sortStaffName(a, b) {
        const result = sortStrings(a.firstName, b.firstName);

        return result !== 0 ? result : sortStrings(a.lastName, b.lastName);
    }

    static sortSector(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortLot(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortOrchard(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortBlock(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortBlockByCode(a, b) {
        return sortStrings(a.code, b.code);
    }

    static sortVariety(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortDefects(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortJob(a, b) {
        return sortStrings(a.name, b.name);
    }

    static sortArea(a, b) {
        return sortStrings(a.code, b.code);
    }

    static sortRow(a, b) {
        return a.number - b.number;
    }

    static sortTaskTypes(a, b) {
        return sortStrings(a.code, b.code);
    }

    static sortPlainString(a, b) {
        return sortStrings(a, b);
    }

    static sortPlainNumbers(a, b) {
        return sortNumbers(a, b);
    }

    static sortCaseInsensitive(a, b) {
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
    }

    static formatNumber(prefix, number, decimalDigitNumber = 2, suffix = '') {
        if (number == null || number === 0) return '';

        return Number.isInteger(Number(number))
            ? `${prefix}${number}${suffix}`
            : `${prefix}${Number(number).toFixed(decimalDigitNumber)}${suffix}`;
    }

    static moveItem(arr, old_index, new_index) {
        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length;
            while (k-- + 1) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    // Deprecated, TODO: Refactor and use ApiService
    static async downloadExport(url, headers, filters) {
        const XHR = new XMLHttpRequest();
        const FD = new FormData();

        // Add data into FormData obj
        FD.append('paramsJson', JSON.stringify(filters));

        XHR.responseType = 'blob';

        const promise = new Promise((resolve, reject) => {
            XHR.addEventListener('load', event => {
                if (event.target.status === 200) {
                    const blob = event.target.response;
                    var contentDispo = XHR.getResponseHeader('Content-Disposition');
                    var fileName = contentDispo
                        ? contentDispo
                              .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                              .replace('"', '')
                              .replace('"', '')
                        : '';
                    saveBlob(blob, fileName);
                    resolve();
                } else if (event.target.status === 500) {
                    notify({ message: NotificationMessage.DownloadFailed, type: 'error' });
                    reject();
                }
            });

            XHR.addEventListener('error', event => {
                notify({ message: NotificationMessage.DownloadFailed, type: 'error' });
                reject();
            });
        });

        // set url, method
        XHR.open('POST', url);
        XHR.setRequestHeader('Authorization', headers['Authorization']);

        XHR.send(FD);

        const saveBlob = (blob, fileName) => {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.dispatchEvent(new MouseEvent('click'));
        };

        await promise;
    }

    static getHeaders = () => {
        const token = window.localStorage.getItem('token');

        if (!token) {
            return null;
        }

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        return headers;
    };

    static fetchPayroll = () => async dispatch => {
        await getData(ApiEndpoint.MetadataPayroll)
            .then(response => {
                dispatch({
                    type: 'FETCH_PAYROLL_SETTINGS',
                    payload: response || [],
                });
            })
            .catch(error => {
                var status = error.status ? 'Error ' + error.status + ': ' + error.statusText : error.toString();
                notify({ message: status, type: 'error' });
            });
    };

    static fetchSectorOrchardBlocks = () => async dispatch => {
        let sectors, orchardList;
        let orchardsAndBlocks = [];

        try {
            sectors = await getData(ApiEndpoint.MetadataSectors);
        } catch (error) {
            console.log(error);
        }

        try {
            orchardsAndBlocks = await getData(ApiEndpoint.OrchardSitesAndBlocks);
        } catch (error) {
            console.log(error);
        }

        if (sectors.length > 0) {
            // if not empty means have sector then go for the current logic flow
            dispatch({
                type: 'FETCH_SECTOR_NAMES',
                payload: sectors,
            });

            const orchardIds = sectors.map(sec => sec.orchardSiteIds).flat();
            orchardList = orchardsAndBlocks && orchardsAndBlocks.filter(item => orchardIds.includes(item.id));
        } else {
            // if empty means no sector asigned so hide sector list and display all orchards/blocks
            orchardList = orchardsAndBlocks;
        }

        dispatch({
            type: 'FETCH_ORCHARDS_AND_BLOCKS',
            payload: { orchardList, orchardsAndBlocks },
        });

        const blockList = orchardList.reduce((acc, item) => {
            acc = [...acc, ...item.blocks];
            return acc;
        }, []);

        dispatch({
            type: 'UPDATE_BLOCK_LIST',
            payload: blockList,
        });
    };

    static fetchHarvestExportSettings = () => async dispatch => {
        await getData(ApiEndpoint.MetadataHarvestExportSettings)
            .then(response => {
                dispatch({
                    type: 'FETCH_EXPORT_SETTINGS',
                    payload: response || [],
                });
            })
            .catch(error => {
                var status = error.status ? 'Error ' + error.status + ': ' + error.statusText : error.toString();
                notify({ message: status, type: 'error' });
            });
    };

    static getUserTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    // TO DO: Remove this function and use ApiService
    static async handleAjaxRequest(url, method, getHeaders, data, success, fail, type, showToast) {
        await defaultApiClient
            .request({
                url,
                method,
                data: typeof data === 'object' ? convertJsonToFormData(data) : JSON.parse(data),
            })
            .then(response => {
                success(response.data);
                if (showToast) {
                    notify({ message: NotificationMessage.Success });
                }
                return response.data;
            })
            .catch(error => {
                fail(error.response ? error.response : error);
            });
    }
}

function sortStrings(a, b) {
    try {
        if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        }
        return 0;
    } catch (e) {
        console.log('Error on sortStrings', e);
    }
}

function sortNumbers(a, b) {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }

    return 0;
}
