export enum NotificationMessage {
    Success = 'Success',
    ErrorTryAgain = 'Error occurred, please try again later ...',
    UpdateSucceed = 'Update success',
    UpdateFailed = 'Update failed',
    ValueRequired = 'Value cannot be blank',
    NumberRequired = 'Value must be a number',
    LettersOrNumbersOnly = 'Please input letters or numbers only',
    ValueExist = 'Value already exists',
    DownloadFailed = 'Download failed',
    StaffExist = 'Staff member already exists',
    StaffCreated = 'New staff created',
    StaffSaved = 'Staff saved',
    StaffFirstNameRequired = 'Staff first name cannot be blank',
    StaffLastNameRequired = 'Staff last name cannot be blank',
    PickCreated = 'New Pick created',
    PickDeleted = 'Pick deleted',
    PickUpdated = 'Pick updated',
    DefectCreated = 'New defect created',
    DefectDeleted = 'Defect deleted',
    DefectUpdated = 'Defect updated',
    DefectNotExist = 'Defect type not exist',
    QualityCreated = 'New Quality created',
    QualityDeleted = 'Quality deleted',
    QualityUpdated = 'Quality updated',
    VarietyCreated = 'New Variety created',
    VarietyDeleted = 'Variety deleted',
    VarietyUpdated = 'Variety updated',
    VarietyRequired = 'Variety cannot be blank',
    VarietyExist = 'Variety already exists',
    VarietyNotFound = 'Variety not found',
    CompleteForm = 'Please fill the form completely',
    OperatorCreated = 'New operator created',
    OperatorUpdated = 'Operator updated',
    OperatorDeleted = 'Operator deleted',
    ProductUpdated = 'Product updated',
    ProductCreated = 'New product created',
    ProductDeleted = 'Product deleted',
    MachineCreated = 'New machine created',
    MachineUpdated = 'Machine updated',
    MachineDeleted = 'Machine deleted',
    TankCreated = 'New tank created',
    TankDeleted = 'Tank deleted',
    TankUpdated = 'Tank updated',
    CountryCreated = 'New country is added',
    CountryUpdated = 'Country is updated',
    CountryDeleted = 'Deleted country',
    CountryRequired = 'Country cannot be blank',
    ReportConfigSaved = 'Report configuration saved',
    TimesheetSaved = 'Timesheet saved',
    AreaExist = 'Area already exists',
    AreaRequired = 'Area cannot be blank',
    RowExist = 'Row already exists',
    CredentialExpired = 'Your credential has expired! Please log in again.',
    EmailRequired = 'Email address cannot be blank',
    EmailInvalid = 'Email address not valid',
    ActivityRequired = 'Activity cannot be blank',
    ActivityExist = 'Activity already exists',
    DefaultInvalid = 'Default is not a valid number',
    WageRateBackdated = 'Wage rate backdated',
    BlockRequired = 'Block cannot be blank',
    BlockExist = 'Block already exists',
    RowNeedNumber = 'Row must be a number',
    RowsNumberRequired = 'Number of rows must be a number',
    TreesNumberRequired = 'Number of trees must be a number',
    TaskTypeRequired = 'Task type cannot be blank',
    TaskTypeSaved = 'Task type saved',
    TaskTypeDeleted = 'Task type deleted',
    SectorUpdated = 'Sector updated',
    SectorCreated = 'New sector created',
    SectorDeleted = 'Sector deleted',
    SwitchOrchardFailed = 'Error switching orchards',
    SubBlockCreated = 'New sub block created',
    SubBlockEdited = 'Sub block edited',
    SubBlockDeleted = 'Sub block deleted',
    SubBlocksCannotBeLoaded = 'Sub blocks cannot be loaded',
    JobDeleted = 'Job deleted',
    JobCreated = 'Job created',
    JobUpdated = 'Job updated',
    BlockCreated = 'New block added',
    BlockEdited = 'Block edited',
    BlockDeleted = 'Block deleted',
    InvalidInput = 'Invalid input',
    UniqueLotName = 'Please enter a new unique lot name',
    UniqueSectorName = 'Please enter a new unique sector name',
    UniqueOrchardSiteName = 'Please enter a new unique ranch name',
    UniqueBlockName = 'Please enter a new unique block name',
    UniqueSubBlockName = 'Please enter a new unique sub block name',
    UniqueRowNumber = 'Please enter a new unique row number',
    UniqueVarietyName = 'Please enter a new unique variety name',
    UniquePickName = 'Please enter a new unique pick',
    UniqueQualityName = 'Please enter a new unique quality name',
    UniqueDefectName = 'Please enter a new unique defect name',
    RequiredLotName = 'Lot name is required',
    RequiredSectorName = 'Sector name is required',
    RequiredOrchardSites = 'Please select at least one orchard site',
    RequiredOrchardSiteName = 'Ranch name is required',
    RequiredBlockName = 'Block name is required',
    RequiredSubBlockName = 'Sub Block name is required',
    LotUpdated = 'Lot updated',
    LotCreated = 'New lot created',
    LotDeleted = 'Lot deleted',
    OrchardSiteUpdated = 'Orchard site updated',
    OrchardSiteCreated = 'New orchard site created',
    OrchardSiteDeleted = 'Orchard site deleted',
    RowUpdated = 'Row updated',
    RowCreated = 'Row created',
    RowDeleted = 'Row deleted',
    BiggerOrEqualToZero = 'The value must be bigger than(equal to) 0 ',
    IntegerOnly = 'Please input an integer',
    LeaveTypeDeleted = 'Leave type deleted',
    LeaveTypeCreated = 'Leave type created',
    LeaveTypeUpdated = 'Leave type updated',
    CannotSelectGapPayRun = 'Only complete pay runs can be selected',
    PayRunLocked = 'Pay Run locked',
    PayRunUnlocked = 'Pay Run Unlocked',
    CannotSelectBackDateInLockedPayRun = 'Selection includes locked payruns. Please unlock payruns to continue.',
    StaffImportContactTeam = 'Sorry, we found a problem while validating your CSV file. Please contact our team.',
    CrossPlatformMessageExchangeError = 'Sorry, an internal error has occurred in the application. Please contact our team.',
    LoginErrorMessage = 'Sorry, we could not log you in. Please contact our team.',
    MetadataErrorMessage = 'We could not load required information',
}
