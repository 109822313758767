import axios from 'axios';
import { useEffect } from 'react';
import config from 'src/config';
import { errorInterceptor, requestInterceptor, responseInterceptor } from './interceptorHandlers';
import { useAuth } from 'src/hooks/useAuth';

export enum ApiCollection {
    Default,
    Frontend,
    PayrollService,
    SpectreV3,
    SpectreDashboard,
}

// TO DO: Remove the export when the defaultApiClient is no longer directly invoked (should only be used via httpMethods.ts)
export const defaultApiClient = axios.create({
    baseURL: config.BACKEND_API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

const feApiClient = axios.create({
    baseURL: window.location.origin,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

const payrollServiceApiClient = axios.create({
    baseURL: config.PAYROLL_SERVICE_API_BASE_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

const spectreDashboardApiClient = axios.create({
    baseURL: config.SPECTRE_DASHBOARD_API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

const spectreV3ApiClient = axios.create({
    baseURL: config.SPECTRE_API_V3_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

export const selectApiClient = (api: ApiCollection) => {
    const selectedApiClient = {
        [ApiCollection.Default]: defaultApiClient,
        [ApiCollection.PayrollService]: payrollServiceApiClient,
        [ApiCollection.SpectreDashboard]: spectreDashboardApiClient,
        [ApiCollection.SpectreV3]: spectreV3ApiClient,
        [ApiCollection.Frontend]: feApiClient,
    }[api];

    if (!selectedApiClient) {
        throw new Error('Invalid API client');
    }

    return selectedApiClient;
};

export const ApiServiceInterceptor = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuth();

    // Set up interceptors for each API client
    useEffect(() => {
        const defaultReqInterceptorId = defaultApiClient.interceptors.request.use(config =>
            requestInterceptor(auth, config, ApiCollection.Default)
        );
        const defaultRespInterceptorId = defaultApiClient.interceptors.response.use(responseInterceptor, error =>
            errorInterceptor(auth, error, ApiCollection.Default, defaultApiClient)
        );

        const payrollReqInterceptorId = payrollServiceApiClient.interceptors.request.use(config =>
            requestInterceptor(auth, config, ApiCollection.PayrollService)
        );
        const payrollRespInterceptorId = payrollServiceApiClient.interceptors.response.use(responseInterceptor, error =>
            errorInterceptor(auth, error, ApiCollection.PayrollService, payrollServiceApiClient)
        );

        const spectreDashboardApiReqInterceptorId = spectreDashboardApiClient.interceptors.request.use(config =>
            requestInterceptor(auth, config, ApiCollection.SpectreDashboard)
        );
        const spectreDashboardApiRespInterceptorId = spectreDashboardApiClient.interceptors.response.use(
            responseInterceptor,
            error => errorInterceptor(auth, error, ApiCollection.SpectreDashboard, spectreDashboardApiClient)
        );

        const spectreV3ReqInterceptorId = spectreV3ApiClient.interceptors.request.use(config =>
            requestInterceptor(auth, config, ApiCollection.SpectreV3)
        );
        const spectreV3RespInterceptorId = spectreV3ApiClient.interceptors.response.use(responseInterceptor, error =>
            errorInterceptor(auth, error, ApiCollection.SpectreV3, spectreV3ApiClient)
        );

        // Cleanup interceptors on unmount
        return () => {
            defaultApiClient.interceptors.request.eject(defaultReqInterceptorId);
            defaultApiClient.interceptors.response.eject(defaultRespInterceptorId);
            payrollServiceApiClient.interceptors.request.eject(payrollReqInterceptorId);
            payrollServiceApiClient.interceptors.response.eject(payrollRespInterceptorId);
            spectreDashboardApiClient.interceptors.request.eject(spectreDashboardApiReqInterceptorId);
            spectreDashboardApiClient.interceptors.response.eject(spectreDashboardApiRespInterceptorId);
            spectreV3ApiClient.interceptors.request.eject(spectreV3ReqInterceptorId);
            spectreV3ApiClient.interceptors.response.eject(spectreV3RespInterceptorId);
        };
    }, []);

    return children as React.ReactElement;
};
