import classnames from 'classnames';
import './font/hectre-icon.css';
import styles from './Icon.module.scss';

export const Icon = ({ icon, className }: { icon: string; className?: string }) => {
    return (
        <span className={classnames(styles['outer-icon'], className || '')}>
            <i className={`hectre-icon-${icon}`}></i>
        </span>
    );
};
