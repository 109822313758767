import { getIdTokenAsString } from 'src/helpers/auth';
import { AxiosInstance } from 'axios';
import { ApiCollection } from '.';
import { AuthDetails } from 'src/models/auth';

let isRefreshing = false;
let failedQueue: { resolve: (value: unknown | PromiseLike<unknown>) => void; reject: (reason?: any) => void }[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(request => {
        if (error) {
            request.reject(error);
        } else {
            request.resolve(token);
        }
    });

    failedQueue = [];
};

export const handleRefreshToken = async (
    auth: AuthDetails,
    error: any,
    api: ApiCollection,
    apiClient: AxiosInstance
) => {
    console.log('handleRefreshToken');
    const originalRequest = error.config;

    if ((error.message == 'Network Error' || error.response?.status === 401) && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return apiClient(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }

        try {
            originalRequest._retry = true;
            isRefreshing = true;

            const newToken = await getNewToken(auth, api);
            originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
            processQueue(null, newToken);

            isRefreshing = false;
            return apiClient(originalRequest);
        } catch (err) {
            console.error(error);
            await auth.logout();

            isRefreshing = false;
            failedQueue.forEach(promiseLike => promiseLike.reject(error));
        }
    }

    return Promise.reject(error);
};

const getNewToken = async (auth: AuthDetails, api: ApiCollection) => {
    if (api === ApiCollection.PayrollService) {
        return await getIdTokenAsString(auth, true);
    }

    return await auth.refreshToken();
};
