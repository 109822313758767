import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon } from 'src/components_v2/common/Icon';
import styles from './notification.module.scss';

interface NotifyProps {
    message: string;
    autoClose?: number;
    type?: 'info' | 'warning' | 'error' | 'success';
    theme?: 'light' | 'dark' | 'colored';
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center';
    pauseOnHover?: boolean;
    pauseOnFocusLoss?: boolean;
    title?: string;
}

const customIcon = (type: string) => {
    if (type === 'error') {
        return <Icon icon="warning" className={styles['custom-icon__error']} />;
    }
    if (type === 'warning') {
        return <Icon icon="info-filled" className={styles['custom-icon__warning']} />;
    }
    return <Icon icon="success" className={styles['custom-icon__success']} />;
};

export const notify = (options: NotifyProps) => {
    const messageType = options.title ? 'long' : 'short';
    const visibleType = options.title ? 'visible' : 'inVisible';
    const notifyType = options.type || 'success';
    return toast[notifyType](
        <div className="here-first-item-inside-toast">
            <div className={styles[`toast-title__${visibleType}`]}>{options.title || null}</div>
            <div className={styles[`toast-body__${messageType}`]}>{options.message}</div>
        </div>,
        {
            className: styles[`custom-${messageType}__${notifyType}`],
            bodyClassName: styles['custom-body'],
            progressClassName: styles[`custom-progressBar__${notifyType}`],
            position: options.position || 'bottom-right',
            icon: options.title ? false : customIcon(notifyType),
            hideProgressBar: Boolean(options.title),
            pauseOnHover: options.pauseOnHover || true,
            pauseOnFocusLoss: options.pauseOnFocusLoss || true,
            closeOnClick: false,
            theme: options.theme || 'light',
            autoClose: options.title ? false : options.autoClose || 5000,
        }
    );
};
