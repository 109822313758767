import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Insights = lazy(() => import('src/components/Insights'));
const Layout = lazy(() => import('src/components/Layout'));
const Maps = lazy(() => import('src/components/Maps'));
const Harvest = lazy(() => import('src/components/Picking'));
const Scout = lazy(() => import('src/components/Scout'));
const NewTimesheet = lazy(() =>
    import('src/pages/Timesheet').then(({ Timesheet }) => ({
        default: Timesheet,
    }))
);

const Analytics = lazy(() =>
    import('src/pages/Analytics').then(({ Analytics }) => ({
        default: Analytics,
    }))
);

const AdminJobs = lazy(() => import('src/pages/Admin/Jobs/Jobs').then(({ Jobs }) => ({ default: Jobs })));
const AdminTasks = lazy(() => import('src/pages/Admin/Jobs/Tasks').then(({ Tasks }) => ({ default: Tasks })));
const AdminStaff = lazy(() => import('src/pages/Admin/Staff').then(({ Staff }) => ({ default: Staff })));
const Payroll = lazy(() => import('src/pages/Payroll').then(({ Payroll }) => ({ default: Payroll })));
const AdminDefault = lazy(() =>
    import('src/pages/Admin/General/Default').then(({ AdminDefault }) => ({
        default: AdminDefault,
    }))
);

const PackhouseAnalytics = lazy(() =>
    import('src/pages/PackhouseAnalytics').then(({ PackhouseAnalytics }) => ({
        default: PackhouseAnalytics,
    }))
);

const AdminExportCountries = lazy(() =>
    import('src/pages/Admin/General/ExportCountries').then(({ AdminExportCountries }) => ({
        default: AdminExportCountries,
    }))
);

const AdminLeaveTypes = lazy(() =>
    import('src/pages/Admin/General/LeaveTypes').then(({ AdminLeaveTypes }) => ({
        default: AdminLeaveTypes,
    }))
);

const AdminRateTreatmentContractorUnit = lazy(() =>
    import('src/pages/Admin/General/RateTreatmentContractorUnit').then(({ AdminRateTreatmentContractorUnit }) => ({
        default: AdminRateTreatmentContractorUnit,
    }))
);

const AdminPayroll = lazy(() =>
    import('src/pages/Admin/Payroll').then(({ AdminPayroll }) => ({
        default: AdminPayroll,
    }))
);

const AdminStaffForm = lazy(() =>
    import('src/pages/Admin/Staff/StaffForm').then(({ AdminStaffForm }) => ({
        default: AdminStaffForm,
    }))
);

const AdminVarieties = lazy(() =>
    import('src/pages/Admin/Varieties/Types').then(({ AdminVarieties }) => ({
        default: AdminVarieties,
    }))
);

const MyGrowers = lazy(() =>
    import('src/pages/MyGrowers').then(({ MyGrowers }) => ({
        default: MyGrowers,
    }))
);

const Spray = lazy(() =>
    import('src/pages/Spray').then(({ Spray: Spray }) => ({
        default: Spray,
    }))
);

const AdminLot = lazy(() =>
    import('src/pages/Admin/Ranches/Lot').then(({ Lots }) => ({
        default: Lots,
    }))
);

const AdminOrchards = lazy(() =>
    import('src/pages/Admin/Ranches/Orchards').then(({ Orchards }) => ({
        default: Orchards,
    }))
);

const AdminSector = lazy(() =>
    import('src/pages/Admin/Ranches/Sector').then(({ Sectors }) => ({
        default: Sectors,
    }))
);

const NewQCDefects = lazy(() =>
    import('src/pages/Admin/Varieties/QCDefects').then(({ NewQCDefects }) => ({
        default: NewQCDefects,
    }))
);

const AdminVarietyDefaults = lazy(() =>
    import('src/pages/Admin/Varieties/defaults').then(({ Defaults }) => ({
        default: Defaults,
    }))
);

const AdminPicks = lazy(() =>
    import('src/pages/Admin/Varieties/PicksAndQualities').then(({ PicksAndQualities }) => ({
        default: PicksAndQualities,
    }))
);

const AdminQualities = lazy(() =>
    import('src/pages/Admin/Varieties/PicksAndQualities').then(({ PicksAndQualities }) => ({
        default: PicksAndQualities,
    }))
);

const Login = lazy(() =>
    import('src/pages/Authentication/Login').then(({ Login }) => ({
        default: Login,
    }))
);

const ForgotPassword = lazy(() =>
    import('src/pages/Authentication/Forgot').then(({ ForgotPassword }) => ({
        default: ForgotPassword,
    }))
);

const UnassignedItem = lazy(() =>
    import('src/pages/Payroll/UnassignedItem').then(({ UnassignedItem }) => ({
        default: UnassignedItem,
    }))
);

const PayrollDetails = lazy(() =>
    import('src/pages/Payroll/Details').then(({ PayrollDetails }) => ({
        default: PayrollDetails,
    }))
);

const Reports = lazy(() =>
    import('src/pages/Reports').then(({ Reports }) => ({
        default: Reports,
    }))
);

const ReportConfiguration = lazy(() =>
    import('src/pages/Reports/Configuration').then(({ ReportConfiguration }) => ({
        default: ReportConfiguration,
    }))
);

const Home = lazy(() =>
    import('src/pages/Home').then(({ Home }) => ({
        default: Home,
    }))
);

const GraderRuns = lazy(() =>
    import('src/pages/Admin/GraderRuns').then(({ GraderRuns }) => ({
        default: GraderRuns,
    }))
);

const GraderRunForm = lazy(() =>
    import('src/pages/Admin/GraderRunForm').then(({ GraderRunForm }) => ({
        default: GraderRunForm,
    }))
);

const GraderRunResults = lazy(() =>
    import('src/pages/Admin/GraderRunResults').then(({ GraderRunResults }) => ({
        default: GraderRunResults,
    }))
);

// AdminLink component is a temporary solution to allow easy transition from Old Admin to the New Redesigned Admin
// Once all clients have used the new admin, then we can remove this component and update the routes again
import AdminLink from 'src/components/AdminLink';
import { MenuUrls } from './helpers/navigation';
import { AuthenticatedRoute, NotAuthenticatedRoute } from './router/ProtectedRoute';
import { LocationModule } from './pages/Admin/Ranches/Orchards/LocationModule';
import { useAuth } from './hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import { SpectreAppContent } from './pages/Spectre/AppContent';
import { useLaunchDarklyIdentify } from './hooks/useLaunchDarklyIdentify';

export const App = () => {
    const auth = useAuth();
    const isLaunchDarklyDIdentified = useLaunchDarklyIdentify();

    if (auth.isAuthenticated === undefined || isLaunchDarklyDIdentified === null) {
        return (
            <Backdrop open={true} sx={theme => ({ backgroundColor: '#fff', zIndex: theme.zIndex.drawer + 1 })}>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <Routes>
            {/* Public routes */}
            <Route
                path={MenuUrls.FORGOT_PASSWORD}
                element={
                    <NotAuthenticatedRoute>
                        <ForgotPassword />
                    </NotAuthenticatedRoute>
                }
            />

            <Route
                path={MenuUrls.LOGIN}
                element={
                    <NotAuthenticatedRoute>
                        <Login />
                    </NotAuthenticatedRoute>
                }
            />

            {/* Private routes */}
            <Route element={<Layout />}>
                {/* HARVEST */}
                <Route
                    path="/home"
                    element={
                        <AuthenticatedRoute canSee={true}>
                            <Home />
                        </AuthenticatedRoute>
                    }
                />

                {/* HARVEST */}
                <Route
                    path="/"
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeHarvest}>
                            <Harvest />
                        </AuthenticatedRoute>
                    }
                />

                {/* ANALYTICS */}
                <Route
                    path={MenuUrls.ANALYTICS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeAnalytics}>
                            <Analytics />
                        </AuthenticatedRoute>
                    }
                />

                {/* PAYROLL */}
                <Route
                    path={MenuUrls.PAYROLL}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeePayroll}>
                            <Payroll />
                        </AuthenticatedRoute>
                    }
                />

                <Route path={MenuUrls.PAYROLL_UNASSIGNED_ITEMS} element={<UnassignedItem />} />

                <Route
                    path={`${MenuUrls.PAYROLL_DETAILS}/:id/:date`}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditTimesheet}>
                            <PayrollDetails />
                        </AuthenticatedRoute>
                    }
                />

                {/* TIMESHEETS */}
                <Route
                    path={MenuUrls.TIMESHEETS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeTimesheets}>
                            <NewTimesheet />
                        </AuthenticatedRoute>
                    }
                />

                {/* SPECTRE */}
                <Route
                    path={`${MenuUrls.SPECTRE}/*`}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeSpectre}>
                            <SpectreAppContent />
                        </AuthenticatedRoute>
                    }
                />

                {/* PACKHOUSE ANALYTICS */}
                <Route
                    path={MenuUrls.FRUIT_QUALITY}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeFruitQuality}>
                            <PackhouseAnalytics />
                        </AuthenticatedRoute>
                    }
                />

                {/* MY GROWERS */}
                <Route
                    path={MenuUrls.MY_GROWERS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeMyGrowers}>
                            <MyGrowers />
                        </AuthenticatedRoute>
                    }
                />

                {/* SPRAY */}
                <Route
                    path={MenuUrls.SPRAY}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeSpray}>
                            <Spray />
                        </AuthenticatedRoute>
                    }
                />

                {/* LOCATION */}
                <Route
                    path={MenuUrls.LOCATION}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeLocation}>
                            <LocationModule />
                        </AuthenticatedRoute>
                    }
                />

                {/* REPORTS */}
                <Route
                    path={MenuUrls.REPORTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeReports}>
                            <Reports />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={`${MenuUrls.REPORTS}/:id`}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeReports}>
                            <ReportConfiguration />
                        </AuthenticatedRoute>
                    }
                />

                {/* MAPS */}
                <Route
                    path={MenuUrls.MAPS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeMaps}>
                            <Maps />
                        </AuthenticatedRoute>
                    }
                />

                {/* INSIGHTS */}
                <Route
                    path={MenuUrls.INSIGHTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeInsights}>
                            <Insights />
                        </AuthenticatedRoute>
                    }
                />

                {/* SCOUT */}
                <Route
                    path={MenuUrls.SCOUT}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canSeeScout}>
                            <Scout />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > STAFF */}
                <Route
                    path={MenuUrls.ADMIN_STAFF}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditStaff}>
                            <AdminStaff />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_STAFF_FORM}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditStaff}>
                            <AdminStaffForm />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > JOBS & TASKS */}
                <Route
                    path={MenuUrls.ADMIN_JOBS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditJobs}>
                            <AdminJobs />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_TASKS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditJobs}>
                            <AdminTasks />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > VARIETIES */}
                <Route
                    path={MenuUrls.ADMIN_VARIETIES}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditVarieties}>
                            <AdminVarieties />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_QUALITY}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditVarieties}>
                            <AdminQualities />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_PICKS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditVarieties}>
                            <AdminPicks />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_DEFECTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditVarieties}>
                            <NewQCDefects />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_DEFAULTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditVarieties}>
                            <AdminVarietyDefaults />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > ORCHARDS */}
                <Route
                    path={MenuUrls.ADMIN_RANCHES}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditOrchardLocationV1}>
                            <AdminLink />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_ORCHARDS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditOrchardLocationV2}>
                            <AdminOrchards />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_SECTORS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditSectors}>
                            <AdminSector />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_LOTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditLots}>
                            <AdminLot />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > PAYROLL */}
                <Route
                    path={MenuUrls.ADMIN_PAYROLL}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditPayrollConfiguration}>
                            <AdminPayroll />
                        </AuthenticatedRoute>
                    }
                />

                {/* ADMIN > GENERAL */}
                <Route
                    path={MenuUrls.ADMIN_GENERAL}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminLeaveTypes />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_EXPORT_COUNTRIES}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminExportCountries />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_RATES}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminRateTreatmentContractorUnit />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_TREATMENT}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminRateTreatmentContractorUnit />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_CONTRACTORS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminRateTreatmentContractorUnit />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GENERAL_DEFAULTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminDefault />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_UNITS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canEditGeneralConfiguration}>
                            <AdminRateTreatmentContractorUnit />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRuns />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRuns />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRuns />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS_NEW}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRunForm />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS_EDIT}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRunForm />
                        </AuthenticatedRoute>
                    }
                />

                <Route
                    path={MenuUrls.ADMIN_GRADER_RUNS_RESULTS}
                    element={
                        <AuthenticatedRoute canSee={auth.user?.permissions?.canManageGraderRuns}>
                            <GraderRunResults />
                        </AuthenticatedRoute>
                    }
                />
            </Route>
        </Routes>
    );
};
