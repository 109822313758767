import * as $ from 'jquery';
import * as React from 'react';
import { connect } from 'react-redux';
import Select2 from 'react-select';
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from 'src/components_v2/common/MUI';
import { notify } from 'src/components_v2/common/Notification';
import { NotificationMessage } from 'src/DataContracts/NotificationMessage';
import { isContainSpecialCharacter } from 'src/helpers/string';
import { withRouter } from 'src/router/withRouter';
import Beacon from '../components_v2/common/Beacon';
import * as MetadataState from '../store/Metadata';
import {
    deleteLot,
    deleteSector,
    fetchLots,
    fetchOrchardSites,
    fetchSectors,
    submitLot,
    submitSector,
    fetchVarieties,
} from '../store/Metadata';
import { Utils } from '../store/Utils';
import './Admin.css';
import { Backdrop, CircularProgress } from '@mui/material';

class Admin extends React.Component {
    emptySite = { code: '', name: '', lotId: '', varieties: [] };
    emptyRow = { number: null, numberOfTrees: 0 };
    emptyVariety = {
        variety: { name: '' },
        binWeight: '',
        cartonWeight: '',
        cartonPrice: '',
        packoutPercentage: '',
        fruitType: 'apple',
    };
    emptySector = { id: '', name: '', orchardSiteIds: [] };
    emptyLot = { id: '', name: '', orchardSiteIds: [] };

    constructor(props) {
        super(props);
        this.siteUpdated = this.siteUpdated.bind(this);
        this.blockUpdated = this.blockUpdated.bind(this);
        this.areaUpdated = this.areaUpdated.bind(this);
        this.rowUpdated = this.rowUpdated.bind(this);
        this.addVariety = this.addVariety.bind(this);
        this.removeVariety = this.removeVariety.bind(this);

        this.state = {
            mode: this.props.modeFromUrl ? this.props.modeFromUrl : 'leaves',
            id: '',
            value: '',
            site: Object.assign({}, this.emptySite),
            block: null,
            area: null,
            row: null,
            variety: Object.assign({}, this.emptyVariety),
            sector: { ...this.emptySector, orchardSiteIds: this.props.orchardSites },
            lot: Object.assign({}, this.emptyLot),
            selectedSectorOrchards: [],
            loading: true,
        };
    }

    // API CALL: ORCHARDS
    async saveOrchard() {
        this.setLoading(true);
        await this.props.saveSite(this);
        this.setLoading(false);
    }

    async deleteOrchard() {
        this.setLoading(true);
        await this.props.deleteSite(this);
        this.setLoading(false);
    }

    // API CALL: BLOCKS
    async saveBlock() {
        this.setLoading(true);
        await this.props.saveBlock(this);
        this.setLoading(false);
    }

    async deleteBlock() {
        this.setLoading(true);
        await this.props.deleteBlock(this);
        this.setLoading(false);
    }

    // API CALL: SUB BLOCKS
    async saveSubBlock() {
        this.setLoading(true);
        await this.props.saveArea(this);
        this.setLoading(false);
    }

    async deleteSubBlock() {
        this.setLoading(true);
        await this.props.deleteArea(this);
        this.setLoading(false);
    }

    // API CALL: ROWS
    async saveRow() {
        this.setLoading(true);
        await this.props.saveRow(this);
        this.setLoading(false);
    }

    async deleteRow() {
        this.setLoading(true);
        await this.props.deleteRow(this);
        this.setLoading(false);
    }

    // API CALL: SECTORS
    async saveSector(sector, selectedSectorOrchards) {
        this.setLoading(true);

        const payload = {
            name: sector.name,
            orchardSiteIds: selectedSectorOrchards.map(item => item.id),
        };

        let valid = true;

        if (sector.name.trim() === '') {
            notify({ message: NotificationMessage.RequiredSectorName, type: 'error' });
            valid = false;
        }

        const isInvalidChars = isContainSpecialCharacter(sector.name);

        if (isInvalidChars) {
            valid = false;
            notify({ message: NotificationMessage.InvalidInput, type: 'error' });
        }

        if (valid) {
            if (sector.id === '') {
                // if selectedSectorOrchards is empty means CREATE
                await this.props.submitSector(payload, true);
            } else {
                // PUT
                await this.props.submitSector(payload, false, sector.id);
            }
        }

        this.setLoading(false);
    }

    async deleteSector() {
        this.setLoading(true);
        await this.props.deleteSector(this);
        this.setLoading(false);
    }

    // API CALL: LOTS
    async saveLot(lot) {
        this.setLoading(true);

        const payload = {
            name: lot.name,
        };

        let valid = true;

        const isInvalidChars = isContainSpecialCharacter(lot.name);

        if (lot.name.trim() === '') {
            notify({ message: NotificationMessage.RequiredLotName, type: 'error' });
            valid = false;
        }
        if (isInvalidChars) {
            valid = false;
            notify({ message: NotificationMessage.InvalidInput, type: 'error' });
        }

        if (valid) {
            if (lot.id === '') {
                await this.props.submitLot(payload, true);
            } else {
                await this.props.submitLot(payload, false, lot.id);
            }
        }

        this.setLoading(false);
    }

    async deleteLot() {
        this.setLoading(true);
        await this.props.deleteLot(this);
        this.setLoading(false);
    }

    // HANDLE STATE CHANGES
    setLoading(loading) {
        this.setState({ loading });
    }

    setMode(mode) {
        try {
            this.setState({
                mode: mode,
                id: '',
                value: '',
                site: Object.assign({}, this.emptySite),
                block: null,
                area: null,
                row: null,
                variety: Object.assign({}, this.emptyVariety),
            });
        } catch (e) {}
    }

    setSite(id) {
        if (id.length > 0) {
            if (!this.props.orchardSites[parseInt(id, 10)].blocks)
                this.props.orchardSites[parseInt(id, 10)].blocks = [];
            this.setState({
                id: id,
                block: null,
                area: null,
                row: null,
                site: Object.assign({}, this.props.orchardSites[parseInt(id, 10)]),
            });
        } else this.setState({ id: '', block: null, area: null, row: null, site: Object.assign({}, this.emptySite) });
    }

    setBlock(id) {
        if (this.state.block == null) {
            if (this.state.site === null) {
                var site = this.props.orchardSites[parseInt(this.state.id, 10)];
                this.setState({ site: site });
            }
        }

        if (id.length > 0) {
            const siteState = this.state.site;
            let block = Object.assign({}, siteState.blocks[parseInt(id, 10)]);
            if (!block.areas) block.areas = [];
            if (!block.newRows) block.newRows = [];
            if (!block.varieties) block.varieties = [];
            block.varieties = block.varieties.slice();
            this.setState({ id: id, area: null, row: null, block: block });
        } else {
            var block = Object.assign({}, this.emptySite);
            block.varieties = [];
            this.setState({ id: '', area: null, row: null, block: block });
        }

        if (this.state.selectedSite) {
            const blocks = this.state.site.blocks;
            for (let i = 0; i < blocks.length; i++) {
                blocks[i].lotID = this.state.selectedSite.lotID;
                if (!blocks[i].areas) blocks[i].areas = [];
                if (!blocks[i].newRows) blocks[i].newRows = [];
                if (!blocks[i].varieties) blocks[i].varieties = [];
                blocks[i].varieties = blocks[i].varieties.slice();
            }

            const block = blocks[id];
            const newState = {
                ...this.state,
                block,
                site: { ...this.state.site, blocks },
                selectedSite: null,
            };

            this.setState(newState);
        }
    }

    setArea(id) {
        if (this.state.area == null) {
            var block = this.state.site.blocks[parseInt(this.state.id, 10)];
            if (!block.areas) block.areas = [];
            if (!block.newRows) block.newRows = [];
            if (!block.varieties) block.varieties = [];
            block.varieties = block.varieties.slice();
            this.setState({ block: block });
        }

        if (id.length > 0) {
            let area = Object.assign({}, this.state.block.areas[parseInt(id, 10)]);
            if (!area.newRows) area.newRows = [];
            if (!area.varieties) area.varieties = [];
            area.varieties = area.varieties.slice();

            this.setState({ id: id, row: null, area: area });
        } else {
            var area = Object.assign({}, this.emptySite);
            area.varieties = [];
            this.setState({ id: '', row: null, area: area });
        }
    }

    setRow(id, forBlock) {
        if (this.state.row == null) {
            if (forBlock) {
                var block = this.state.site.blocks[parseInt(this.state.id, 10)];
                if (!block.areas) block.areas = [];
                if (!block.rows) block.rows = [];
                if (!block.varieties) block.varieties = [];
                block.varieties = block.varieties.slice();
                this.setState({ block: block });
            } else {
                var area = this.state.block.areas[parseInt(this.state.id, 10)];
                if (!area.rows) area.rows = [];
                if (!area.varieties) area.varieties = [];
                area.varieties = area.varieties.slice();
                this.setState({ area: area });
            }
        }

        if (id.length > 0 && id !== 'group') {
            if (!forBlock && this.state.area != null) {
                this.setState({ id: id, row: Object.assign({}, this.state.area.rows[parseInt(id, 10)]) });
            } else {
                this.setState({ id: id, area: null, row: Object.assign({}, this.state.block.rows[parseInt(id, 10)]) });
            }
        } else {
            var row = Object.assign({}, this.emptyRow);
            this.setState({ id: id, row: row });
            if (forBlock) this.setState({ area: null });
        }
    }

    setSector(sector) {
        const sitesRef = this.props.orchardSites;
        if (sector.id.length > 0) {
            let selected = [];
            const newSectorOrchardSite = sitesRef.map(item => {
                if (sector.orchardSiteIds.includes(item.id)) {
                    const newItem = { ...item, isSelected: true };
                    selected.push(newItem);
                    return newItem;
                } else {
                    return { ...item, isSelected: false };
                }
            });
            this.setState({
                id: sector.id,
                sector: { ...sector, orchardSiteIds: newSectorOrchardSite },
                selectedSectorOrchards: selected,
            });
        } else {
            this.setState({
                id: '',
                sector: { ...this.emptySector, orchardSiteIds: sitesRef },
                selectedSectorOrchards: [],
            });
        }
    }

    setLot(lot) {
        if (lot.id.length > 0) {
            let selected = [];
            this.setState({ id: lot.id, lot: { ...lot }, selectedLotOrchards: selected });
        } else {
            this.setState({ id: '', lot: { ...this.emptyLot }, selectedLotOrchards: [] });
        }
    }

    updateSector(event) {
        var sector = this.state.sector;
        sector.name = event.target.value;
        this.setState({ sector: sector });
    }

    updateLot(event) {
        var lot = this.state.lot;
        lot.name = event.target.value;
        this.setState({ lot });
    }

    handleSectorOrchardSelect(e) {
        if (this.state.sector.orchardSiteIds.length === 0) {
            this.setState({
                id: '',
                sector: { ...this.emptySector, orchardSiteIds: this.props.orchardSites },
                selectedSectorOrchards: [],
            });
        } else {
            // put all selected ids in an array as reference
            const idSelected = e.target.value.map((item, index) => {
                // update flag in the meantime
                e.target.value[index].isSelected = true;
                return item.id;
            });
            const newOrchardSiteIds = this.state.sector.orchardSiteIds.map(item => {
                if (idSelected.includes(item.id)) {
                    item.isSelected = !item.isSelected;
                    return item;
                } else {
                    return item;
                }
            });
            // update the orchardSiteIds and selected array
            this.setState({
                sector: { ...this.state.sector, orchardSiteIds: newOrchardSiteIds },
                selectedSectorOrchards: e.target.value,
            });
        }
    }

    onOpenSectorOrchardSelect(e) {
        if (this.state.sector.orchardSiteIds.length === 0) {
            this.setState({
                id: '',
                sector: { ...this.emptySector, orchardSiteIds: this.props.orchardSites },
                selectedSectorOrchards: [],
            });
        }
    }

    siteUpdated(event) {
        var site = this.state.site;
        site[event.target.id] = event.target.value;
        this.setState({ site: site });
    }

    blockUpdated(event) {
        var block = this.state.block;
        if (event.target.id === 'blockcode') block.code = event.target.value;
        else if (event.target.id === 'hectares') block.hectares = Utils.filterNumber(event.target.value);
        else if (event.target.id === 'costCode') block.costCode = event.target.value;
        else block[event.target.id] = event.target.value.replace(/[^0-9.]/g, '');
        this.setState({ block: block });
    }

    areaUpdated(event) {
        var area = this.state.area;
        area[event.target.id] = event.target.value;
        this.setState({ area: area });
    }

    rowUpdated(event) {
        var row = this.state.row;
        row[event.target.id] = event.target.value.replace(/[^0-9]/g, '');
        this.setState({ row: row });
    }

    addVariety(event) {
        const selectedVariety = this.props.varieties[event.target.value];
        if (selectedVariety.name !== '') {
            //     var variety = event.target.value;
            var varieties = this.state.area != null ? this.state.area.varieties : this.state.block.varieties;

            // check if selectedVariety is exist in area or block obj
            const match = varieties.length > 0 ? varieties.filter(item => item.id === selectedVariety.id) : [];

            if (match.length === 0) {
                // if (varieties.indexOf(variety) < 0) {

                varieties.push(selectedVariety);

                varieties.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

                if (this.state.area != null) this.setState({ area: this.state.area });
                else this.setState({ block: this.state.block });
            }

            // clear the select value in order to make further select
            if (this.state.area != null) $('#AreaVariety').val('');
            else $('#BlockVariety').val('');
        }
    }

    removeVariety(varietyId) {
        if (this.state.area != null) {
            const newVarieties = this.state.area.varieties.filter(item => item.id !== varietyId);
            this.setState({ area: { ...this.state.area, varieties: newVarieties } });
        } else {
            const newVarieties = this.state.block.varieties.filter(item => item.id !== varietyId);
            this.setState({ block: { ...this.state.block, varieties: newVarieties } });
        }
    }

    UNSAFE_componentWillMount() {
        if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === 'general') {
            Beacon('General');
        } else {
            Beacon('Default');
        }
    }

    componentDidUpdate(prevProps, prevState) {}

    async componentDidMount() {
        await Promise.all([
            this.props.fetchSectors(),
            this.props.fetchLots(),
            this.props.fetchOrchardSites(),
            this.props.fetchVarieties(),
        ]);
        this.setLoading(false);
    }

    renderOrchardSites({ sectorArr, lotArr }) {
        // Check if hasSector flag before render
        const displaySectors = this.props.metadata.options.hasSectors;

        // Check if hasLots flag before render
        const displayLots = this.props.metadata.options.hasLots;

        // Render two tabs layout
        const renderMultipleTabs = (displaySectors, displayLots) => {
            return (
                <div data-w-tab="Tab 3" className="tab-container w-tab-pane w--tab-active">
                    <div data-duration-in="300" data-duration-out="100" className="sub-frame w-tabs">
                        <div className="sub-nav secondary w-tab-menu">
                            <a
                                className={
                                    'horizontal-nav-tab secondary w-inline-block w-tab-link' +
                                    (this.state.mode === 'sites' ? ' w--current' : '')
                                }
                                onClick={() => this.setMode('sites')}
                            >
                                <div className="horizontal-nav-title secondary">
                                    {this.props.metadata.localisations.Orchards?.toUpperCase() || 'ORCHARDS'}
                                </div>
                            </a>
                            {displaySectors && (
                                <a
                                    className={
                                        'horizontal-nav-tab secondary w-inline-block w-tab-link' +
                                        (this.state.mode === 'sectors' ? ' w--current' : '')
                                    }
                                    onClick={() => this.setMode('sectors')}
                                >
                                    <div className="horizontal-nav-title secondary">SECTORS</div>
                                </a>
                            )}
                            {displayLots && (
                                <a
                                    className={
                                        'horizontal-nav-tab secondary w-inline-block w-tab-link' +
                                        (this.state.mode === 'lots' ? ' w--current' : '')
                                    }
                                    onClick={() => this.setMode('lots')}
                                >
                                    <div className="horizontal-nav-title secondary">LOTS</div>
                                </a>
                            )}
                        </div>
                        <div className="w-tab-content">
                            {this.state.mode === 'sites' && renderOrchardsTabContent()}
                            {displaySectors && this.state.mode === 'sectors' && renderSectorsTabContent()}
                            {displayLots && this.state.mode === 'lots' && renderLotsTabContent()}
                        </div>
                    </div>
                </div>
            );
        };

        const renderSectorsTabContent = () => {
            return (
                <div data-w-tab="Tab 7" className="tab-container w-tab-pane w--tab-active">
                    <div className="inner-overflow">
                        <div className="main-container horizontal">
                            <div className="user-column">
                                <div className="list-of-people" style={{ overflow: 'hidden' }}>
                                    <div
                                        className="action-top-row"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.setSector(Object.assign({}, this.emptySector))}
                                    >
                                        <div className="sub-tab-label action-cta">ADD NEW SECTOR</div>
                                        {this.state.sector.id === '' && (
                                            <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                        )}
                                    </div>
                                    <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                        <div
                                            className="tabs-menu w-tab-menu"
                                            style={{ width: '100%', border: 'none', paddingBottom: '200px' }}
                                        >
                                            {sectorArr.sort(Utils.sortSector).map((s, i) => (
                                                <a
                                                    key={i}
                                                    className={
                                                        'sub-tab w-inline-block w-tab-link' +
                                                        (this.state.sector.id === s.id ? ' w--current' : '')
                                                    }
                                                    onClick={() => this.setSector(s)}
                                                    style={{ paddingBottom: '20px' }}
                                                >
                                                    <div className="sub-tab-label">{s.name}</div>
                                                    <div className="tab-action-container">
                                                        {this.state.sector.id === s.id && (
                                                            <div className="editable-icon edit" title="Edit"></div>
                                                        )}
                                                    </div>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                <div className="edit-columnn">
                                    <div className="form-block-container w-form">
                                        <form id="sector-form" name="sector-form" className="w-clearfix">
                                            <div className="left-form-column">
                                                <label className="field-label">{'Sector'}</label>
                                                <input
                                                    type="text"
                                                    className="text-field w-input"
                                                    maxLength={256}
                                                    id="name"
                                                    onChange={e => this.updateSector(e)}
                                                    value={this.state.sector.name}
                                                />
                                                {
                                                    <FormControl style={{ minWidth: '100%' }}>
                                                        <InputLabel id="demo-mutiple-chip-label">Orchards</InputLabel>
                                                        <Select
                                                            id="demo-mutiple-chip"
                                                            multiple
                                                            value={this.state.selectedSectorOrchards}
                                                            onOpen={e => this.onOpenSectorOrchardSelect(e)}
                                                            onChange={e => this.handleSectorOrchardSelect(e)}
                                                            input={<Input id="select-multiple-chip" />}
                                                            renderValue={selected => (
                                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {selected.map(value => (
                                                                        <Chip
                                                                            key={value.id}
                                                                            label={value.name}
                                                                            style={{ margin: '0.5em' }}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.sector.orchardSiteIds
                                                                .sort((a, b) => (a.isSelected ? -1 : 1))
                                                                .map(sec => (
                                                                    <MenuItem key={sec.id} value={sec}>
                                                                        {sec.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                        </form>
                                        <div className="buttons-container">
                                            <a
                                                className="button w-button"
                                                onClick={() =>
                                                    this.saveSector(
                                                        this.state.sector,
                                                        this.state.selectedSectorOrchards
                                                    )
                                                }
                                            >
                                                SAVE
                                            </a>
                                            {this.state.sector.id.length > 0 && (
                                                <a
                                                    className="button delete w-button"
                                                    onClick={() => this.deleteSector()}
                                                >
                                                    DELETE
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        };

        const renderLotsTabContent = () => {
            return (
                <div data-w-tab="Tab 7" className="tab-container w-tab-pane w--tab-active">
                    <div className="inner-overflow">
                        <div className="main-container horizontal">
                            <div className="user-column">
                                <div className="list-of-people" style={{ overflow: 'hidden' }}>
                                    <div
                                        className="action-top-row"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.setLot(Object.assign({}, this.emptyLot))}
                                    >
                                        <div className="sub-tab-label action-cta">ADD NEW LOT</div>
                                        {this.state.lot.id === '' && (
                                            <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                        )}
                                    </div>
                                    <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                        <div
                                            className="tabs-menu w-tab-menu"
                                            style={{ width: '100%', border: 'none', paddingBottom: '200px' }}
                                        >
                                            {lotArr.sort(Utils.sortLot).map((s, i) => {
                                                return (
                                                    <a
                                                        key={i}
                                                        className={`sub-tab w-inline-block w-tab-link ${
                                                            this.state.lot.id === s.id ? ' w--current' : ''
                                                        }`}
                                                        onClick={() => this.setLot(s)}
                                                        style={{ paddingBottom: '20px' }}
                                                    >
                                                        <div className="sub-tab-label">{s.name}</div>
                                                        <div className="tab-action-container">
                                                            {this.state.lot.id === s.id && (
                                                                <div className="editable-icon edit" title="Edit"></div>
                                                            )}
                                                        </div>
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                <div className="edit-columnn">
                                    <div className="form-block-container w-form">
                                        <form
                                            id="sector-form"
                                            name="sector-form"
                                            className="w-clearfix"
                                            onSubmit={e => {
                                                e.preventDefault();
                                                this.saveLot(this.state.lot, this.state.selectedLotOrchards);
                                                this.setLot(Object.assign({}, this.emptyLot));
                                            }}
                                        >
                                            <div className="left-form-column">
                                                <label className="field-label">{'LOT'}</label>
                                                <input
                                                    type="text"
                                                    className="text-field w-input"
                                                    maxLength={256}
                                                    id="name"
                                                    onChange={e => this.updateLot(e)}
                                                    value={this.state.lot.name}
                                                />
                                            </div>
                                        </form>
                                        <div className="buttons-container">
                                            <a
                                                className="button w-button"
                                                onClick={() =>
                                                    this.saveLot(this.state.lot, this.state.selectedLotOrchards)
                                                }
                                            >
                                                SAVE
                                            </a>
                                            {this.state.lot.id.length > 0 && (
                                                <a className="button delete w-button" onClick={() => this.deleteLot()}>
                                                    DELETE
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        };

        const renderOrchardsTabContent = () => {
            const lotArrToSelect2 = arr => {
                const result = [];
                result.push({ value: null, label: 'None' });
                try {
                    arr.forEach(item => result.push({ value: item.id, label: item.name }));
                } catch (e) {}
                return result;
            };

            const selectedBlockLot = ({ state, lots }) => {
                try {
                    const chosenBlock = state.site.blocks.find(block => block.id === state.block.id);
                    const result = selectedLotInSelect2({ id: chosenBlock.lotID, list: lotArrToSelect2(lots) });
                    return result;
                } catch (e) {}
            };

            const selectedLotInSelect2 = ({ id, list }) => {
                return list.find(item => item.value === id);
            };

            const renderOrchards = () => {
                return (
                    <div data-w-tab="Tab 7" className="tab-container w-tab-pane w--tab-active">
                        <div className="inner-overflow" style={{ paddingBottom: '0' }}>
                            <div className="main-container" style={{ paddingTop: '1%' }}>
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setSite('')}
                                >
                                    <div className="sub-tab-label action-cta">
                                        ADD NEW {this.props.metadata.localisations.Site || 'Orchard'}
                                    </div>
                                    {this.state.id === '' && this.state.block === null && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                    <div className="tabs-menu w-tab-menu" style={{ paddingBottom: '200px' }}>
                                        {this.props.orchardSites.sort(Utils.sortOrchard).map((s, i) => (
                                            <a
                                                key={i}
                                                className={
                                                    'sub-tab w-inline-block w-tab-link' +
                                                    ((this.state.site.code === s.code &&
                                                        this.state.site.name === s.name) ||
                                                    (this.state.id === i.toString() && this.state.block == null)
                                                        ? ' w--current'
                                                        : '')
                                                }
                                                onClick={() => this.setSite(i.toString())}
                                                style={{ paddingBottom: '20px' }}
                                            >
                                                <div className="sub-tab-label">
                                                    {s.code !== s.name ? s.name + ' (' + s.code + ')' : s.name}{' '}
                                                </div>
                                                <div className="tab-action-container">
                                                    {this.state.id === i.toString() && this.state.block === null && (
                                                        <div className="editable-icon edit" title="Edit"></div>
                                                    )}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    {(this.state.id.length > 0 || this.state.block != null) && renderBlocks()}
                                    {this.state.site != null &&
                                        this.state.block == null &&
                                        renderEditingViewForOrchard()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderBlocks = () => {
                return (
                    <div className="tabs-content w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                            <div className="subtab-container">
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setBlock('')}
                                >
                                    <div className="sub-tab-label action-cta">ADD NEW BLOCK</div>
                                    {this.state.id === '' && this.state.area === null && this.state.row === null && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                    <div
                                        className="tabs-menu w-tab-menu"
                                        style={{ height: 'calc(100vh - 290px)', paddingBottom: '200px' }}
                                    >
                                        {this.state.site.blocks.sort(Utils.sortBlockByCode).map((b, i) => (
                                            <a
                                                key={i}
                                                className={
                                                    'sub-tab w-inline-block w-tab-link' +
                                                    (this.state.block != null &&
                                                    (this.state.block.code === b.code ||
                                                        (this.state.id === i.toString() &&
                                                            this.state.area == null &&
                                                            this.state.row == null))
                                                        ? ' w--current'
                                                        : '')
                                                }
                                                onClick={() => this.setBlock(i.toString())}
                                                style={{ paddingBottom: '20px' }}
                                            >
                                                <div className="sub-tab-label sub-layer">{b.code}</div>
                                                <div className="tab-action-container">
                                                    {this.state.id === i.toString() &&
                                                        this.state.block != null &&
                                                        this.state.area === null &&
                                                        this.state.row === null && (
                                                            <div className="editable-icon edit" title="Edit"></div>
                                                        )}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    {/**
                                     * Note
                                     * -----------------------------------------------------------------------------
                                     * this.state.block.varieties will be an empty array if there is no varieties
                                     * this.state.block.rows will be undefined if there is no rows
                                     */}
                                    {((this.state.block &&
                                        this.state.block.rows &&
                                        this.state.block.rows.length === 0 &&
                                        this.state.block.areas &&
                                        this.state.block.areas.length === 0 &&
                                        this.state.row === null &&
                                        this.state.block.varieties &&
                                        !this.state.block.varieties.length > 0) || // block selected but no row or area selected
                                        (this.state.block &&
                                            this.state.block.areas &&
                                            !this.state.block.rows &&
                                            !this.state.block.varieties.length) || // there is area under this block
                                        (this.state.block &&
                                            this.state.block.rows &&
                                            this.state.block.rows.length < 0) || // there is no row under this block
                                        (this.state.block &&
                                            this.state.block.rows === null &&
                                            this.state.block.areas.length === 0 &&
                                            this.state.block.varieties &&
                                            !this.state.block.varieties.length > 0)) && // there is no row or area or variety under this block
                                        renderSubblocks()}
                                    {this.state.area == null &&
                                        this.state.block != null &&
                                        (this.state.row != null || this.state.id.length > 0) &&
                                        !this.state.block.areas.length > 0 &&
                                        renderRowsOnBlocks()}
                                    {this.state.area == null &&
                                        this.state.row == null &&
                                        this.state.block != null &&
                                        renderEditingViewForBlocks()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderRowsOnBlocks = () => {
                return (
                    <div className="w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                            <div className="subtab-container">
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setRow('', true)}
                                >
                                    <div className="sub-tab-label action-cta">ADD NEW ROW</div>
                                    {this.state.id === '' && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setRow('group', true)}
                                >
                                    <div className="sub-tab-label action-cta">ADD GROUP OF ROWS</div>
                                    {this.state.id === 'group' && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                    <div
                                        className="tabs-menu w-tab-menu"
                                        style={{ height: 'calc(100vh - 390px)', paddingBottom: '200px' }}
                                    >
                                        {this.state.block.rows &&
                                            this.state.block.rows.sort(Utils.sortRow).map((r, i) => (
                                                <a
                                                    key={i}
                                                    className={
                                                        'sub-tab w-inline-block w-tab-link' +
                                                        (this.state.row != null && this.state.id === i.toString()
                                                            ? ' w--current'
                                                            : '')
                                                    }
                                                    onClick={() => this.setRow(i.toString(), true)}
                                                    style={{ paddingBottom: '20px' }}
                                                >
                                                    <div className="sub-tab-label sub-layer">{r.number}</div>
                                                    <div className="tab-action-container">
                                                        {this.state.id === i.toString() && this.state.row != null && (
                                                            <div className="editable-icon edit" title="Edit"></div>
                                                        )}
                                                    </div>
                                                </a>
                                            ))}
                                    </div>
                                    {this.state.row != null &&
                                        this.state.area == null &&
                                        renderEditingViewForRows('calc(100vh - 350px)')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderSubblocks = () => {
                return (
                    <div className="w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                            <div className="subtab-container">
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setArea('')}
                                >
                                    <div className="sub-tab-label action-cta">ADD NEW SUB-BLOCK</div>
                                    {this.state.id === '' && this.state.row === null && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                    <div
                                        className="tabs-menu w-tab-menu"
                                        style={{ height: 'calc(100vh - 340px)', paddingBottom: '200px' }}
                                    >
                                        {this.state.block.areas.sort(Utils.sortArea).map((a, i) => (
                                            <a
                                                key={i}
                                                className={
                                                    'sub-tab w-inline-block w-tab-link' +
                                                    (this.state.area != null &&
                                                    (this.state.area.code === a.code ||
                                                        (this.state.id === i.toString() && this.state.row == null))
                                                        ? ' w--current'
                                                        : '')
                                                }
                                                onClick={() => this.setArea(i.toString())}
                                                style={{ paddingBottom: '20px' }}
                                            >
                                                <div className="sub-tab-label sub-layer">{a.code}</div>
                                                <div className="tab-action-container">
                                                    {this.state.id === i.toString() &&
                                                        this.state.area != null &&
                                                        this.state.row === null && (
                                                            <div className="editable-icon edit" title="Edit"></div>
                                                        )}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    {this.state.area != null &&
                                        (this.state.row != null || this.state.id.length > 0) &&
                                        renderRowsOnSubblocks()}
                                    {this.state.row == null &&
                                        this.state.area != null &&
                                        renderEditingViewForSubblocks()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderRowsOnSubblocks = () => {
                return (
                    <div className="w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                            <div className="subtab-container">
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setRow('', false)}
                                >
                                    <div className="sub-tab-label action-cta">ADD NEW ROW</div>
                                    {this.state.id === '' && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div
                                    className="action-top-row"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setRow('group', false)}
                                >
                                    <div className="sub-tab-label action-cta">ADD GROUP OF ROWS</div>
                                    {this.state.id === 'group' && (
                                        <img src="img/Add.png" width="14" alt="" className="new-task-button" />
                                    )}
                                </div>
                                <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                                    <div
                                        className="tabs-menu w-tab-menu"
                                        style={{ height: 'calc(100vh - 440px)', paddingBottom: '200px' }}
                                    >
                                        {this.state.area.rows &&
                                            this.state.area.rows.sort(Utils.sortRow).map((r, i) => (
                                                <a
                                                    key={i}
                                                    className={
                                                        'sub-tab w-inline-block w-tab-link' +
                                                        (this.state.row != null && this.state.id === i.toString()
                                                            ? ' w--current'
                                                            : '')
                                                    }
                                                    onClick={() => this.setRow(i.toString(), false)}
                                                    style={{ paddingBottom: '20px' }}
                                                >
                                                    <div className="sub-tab-label sub-layer">{r.number}</div>
                                                    <div className="tab-action-container">
                                                        {this.state.id === i.toString() && this.state.row != null && (
                                                            <div className="editable-icon edit" title="Edit"></div>
                                                        )}
                                                    </div>
                                                </a>
                                            ))}
                                    </div>
                                    {this.state.row != null && renderEditingViewForRows('calc(100vh - 400px)')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderVarieties = (selectId, stateVarieties) => {
                return (
                    <div>
                        <label className="field-label">ADD VARIETY</label>
                        <select
                            className="dropdown-selection w-select"
                            style={{ height: '45px' }}
                            id={selectId}
                            onChange={this.addVariety}
                        >
                            <option key="" value=""></option>
                            {this.props.varieties.sort(Utils.sortVariety).map((v, index) => (
                                <option key={v.id} value={index}>
                                    {v.name}
                                </option>
                            ))}
                        </select>
                        {stateVarieties.sort(Utils.sortVariety).map(v => (
                            <div
                                key={v.id}
                                className="selected-variety-wrap"
                                onClick={() => this.removeVariety(v.id)}
                                title={`Remove ${v.name}`}
                            >
                                <div className="editable-icon trash"></div>
                                <div className="selected-variety-name">{v.name.replace(/\s/g, ' ')}</div>
                            </div>
                        ))}
                    </div>
                );
            };

            // Editing views
            const renderEditingViewForOrchard = () => {
                return (
                    <div className="tabs-content w-tab-content">
                        <div className="edit-columnn" style={{ width: '100%' }}>
                            <div className="form-block-container w-form" style={{ width: '100%' }}>
                                <form
                                    className="w-clearfix"
                                    onSubmit={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="left-form-column" style={{ width: '100%' }}>
                                        <label className="field-label">
                                            {this.props.metadata.localisations.Site || 'Orchard'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="name"
                                            onChange={this.siteUpdated}
                                            value={this.state.site.name}
                                        />
                                        <label className="field-label">
                                            {this.props.metadata.localisations.OrchardCode || 'Code'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="code"
                                            onChange={this.siteUpdated}
                                            value={this.state.site.code}
                                        />
                                        {displayLots && (
                                            <label className="field-label">
                                                {this.props.metadata.localisations.Lot || 'Lot'}
                                            </label>
                                        )}
                                        {displayLots && (
                                            <Select2
                                                className="cx-select2-container"
                                                // TODO for Nico and Nathan?
                                                // can you please let me know if the API is working for orchard now?
                                                // I will have to test this again (nathan)
                                                // value={selectedLotInSelect2({ id: this.state.site.lotID, list: lotArrToSelect2(lotArr)})}
                                                options={lotArrToSelect2(lotArr)}
                                                onChange={selected => {
                                                    this.setState({
                                                        ...this.state,
                                                        site: { ...this.state.site, lotID: selected.value },
                                                    });
                                                }}
                                            />
                                        )}
                                        <br />
                                        <label className="field-label">
                                            {this.props.metadata.localisations.payrollCostCode || 'Payroll cost code'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="costCode"
                                            onChange={this.siteUpdated}
                                            value={this.state.site.costCode ? this.state.site.costCode : ''}
                                        />
                                    </div>
                                </form>
                                <div className="buttons-container">
                                    <a
                                        className="button w-button"
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            this.saveOrchard();
                                            const siteLotID = this.state.site.lotID;
                                            const siteBlocks = this.state.site.blocks;
                                            if (siteLotID !== null && Array.isArray(siteBlocks)) {
                                                for (let i = 0; i < siteBlocks.length; i++)
                                                    siteBlocks[i] = { ...siteBlocks[i], lotID: siteLotID };
                                                const newState = {
                                                    ...this.state,
                                                    site: { ...this.state.site, blocks: siteBlocks },
                                                    selectedSite: { ...this.state.site, blocks: siteBlocks },
                                                };
                                                this.setState(newState);
                                            }
                                        }}
                                    >
                                        SAVE
                                    </a>
                                    {this.state.id.length > 0 && (
                                        <a
                                            className="button delete w-button"
                                            style={{ width: '100%' }}
                                            onClick={() => this.deleteOrchard()}
                                        >
                                            DELETE
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderEditingViewForBlocks = () => {
                // selectedBlockLot({state: this.state, lots: lotArr});
                return (
                    <div className="tabs-content w-tab-content">
                        <div className="edit-columnn" style={{ width: '100%' }}>
                            <div
                                className="form-block-container w-form custom-block-edit-form"
                                style={{ width: '100%', height: '400px', overflowY: 'scroll' }}
                            >
                                <form className="w-clearfix">
                                    <div className="left-form-column" style={{ width: '100%', paddingRight: '10px' }}>
                                        <label className="field-label">Block</label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="blockcode"
                                            onChange={this.blockUpdated}
                                            value={this.state.block.code}
                                        />
                                        <label className="field-label">
                                            {this.props.metadata.localisations.AreaUnit || 'Hectare'}s
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="hectares"
                                            onChange={this.blockUpdated}
                                            value={this.state.block.hectares ? this.state.block.hectares : ''}
                                        />

                                        {displayLots && (
                                            <label className="field-label">
                                                {this.props.metadata.localisations.Lot || 'Lot'}
                                            </label>
                                        )}
                                        {displayLots && (
                                            <Select2
                                                className="cx-select2-container"
                                                value={selectedLotInSelect2({
                                                    id: this.state.block.lotID,
                                                    list: lotArrToSelect2(lotArr),
                                                })}
                                                options={lotArrToSelect2(lotArr)}
                                                onChange={selected => {
                                                    this.setState({
                                                        ...this.state,
                                                        block: { ...this.state.block, lotID: selected.value },
                                                    });
                                                }}
                                            />
                                        )}
                                        {renderVarieties('BlockVariety', this.state.block.varieties)}

                                        <br />
                                        <label className="field-label">
                                            {this.props.metadata.localisations.payrollCostCode || 'Payroll cost code'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="costCode"
                                            onChange={this.blockUpdated}
                                            value={this.state.block.costCode ? this.state.block.costCode : ''}
                                        />
                                    </div>
                                </form>
                                <div
                                    className="buttons-container"
                                    style={{ paddingRight: '10px', paddingBottom: '5px' }}
                                >
                                    <a
                                        className="button w-button"
                                        style={{ width: '100%' }}
                                        onClick={() => this.saveBlock()}
                                    >
                                        SAVE
                                    </a>
                                    {this.state.id.length > 0 && (
                                        <a
                                            className="button delete w-button"
                                            style={{ width: '100%' }}
                                            onClick={() => this.deleteBlock()}
                                        >
                                            DELETE
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderEditingViewForSubblocks = () => {
                return (
                    <div className="tabs-content w-tab-content">
                        <div className="edit-columnn" style={{ width: '100%' }}>
                            <div
                                className="form-block-container w-form"
                                style={{ width: '100%', height: 'calc(100vh - 350px)', overflowY: 'auto' }}
                            >
                                <form className="w-clearfix">
                                    <div className="left-form-column" style={{ width: '100%', paddingRight: '10px' }}>
                                        <label className="field-label">Sub-block</label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="code"
                                            onChange={this.areaUpdated}
                                            value={this.state.area.code}
                                        />
                                        {renderVarieties('AreaVariety', this.state.area.varieties)}
                                        <br />
                                        <label className="field-label">
                                            {this.props.metadata.localisations.payrollCostCode || 'Payroll cost code'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="costCode"
                                            onChange={this.areaUpdated}
                                            value={this.state.area.costCode ? this.state.area.costCode : ''}
                                        />
                                    </div>
                                </form>
                                <div
                                    className="buttons-container"
                                    style={{ paddingRight: '10px', paddingBottom: '5px' }}
                                >
                                    <a
                                        className="button w-button"
                                        style={{ width: '100%' }}
                                        onClick={() => this.saveSubBlock()}
                                    >
                                        SAVE
                                    </a>
                                    {this.state.id.length > 0 && (
                                        <a
                                            className="button delete w-button"
                                            style={{ width: '100%' }}
                                            onClick={() => this.deleteSubBlock()}
                                        >
                                            DELETE
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            const renderEditingViewForRows = height => {
                return (
                    <div className="tabs-content w-tab-content">
                        <div className="edit-columnn" style={{ width: '100%' }}>
                            <div
                                className="form-block-container w-form"
                                style={{ width: '100%', height: '200px', overflowY: 'auto' }}
                            >
                                <form className="w-clearfix">
                                    <div className="left-form-column" style={{ width: '100%', paddingRight: '10px' }}>
                                        <label className="field-label">
                                            {this.state.id === 'group' && 'Start '}Row
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="number"
                                            onChange={this.rowUpdated}
                                            value={this.state.row.number ? this.state.row.number : ''}
                                        />
                                        {this.state.id === 'group' && (
                                            <div>
                                                <label className="field-label">Number of Rows</label>
                                                <input
                                                    type="text"
                                                    className="text-field w-input"
                                                    maxLength={256}
                                                    id="count"
                                                    onChange={this.rowUpdated}
                                                    value={this.state.row.count ? this.state.row.count : ''}
                                                />
                                            </div>
                                        )}
                                        <label className="field-label">
                                            Number of Trees{this.state.id === 'group' && ' per Row'}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-field w-input"
                                            maxLength={256}
                                            id="numberOfTrees"
                                            onChange={this.rowUpdated}
                                            value={this.state.row.numberOfTrees ? this.state.row.numberOfTrees : ''}
                                        />
                                    </div>
                                </form>
                                <div
                                    className="buttons-container"
                                    style={{ paddingRight: '10px', paddingBottom: '5px' }}
                                >
                                    <a
                                        className="button w-button"
                                        style={{ width: '100%' }}
                                        onClick={() => this.saveRow()}
                                    >
                                        SAVE
                                    </a>
                                    {this.state.id.length > 0 && this.state.id !== 'group' && (
                                        <a
                                            className="button delete w-button"
                                            style={{ width: '100%' }}
                                            onClick={() => this.deleteRow()}
                                        >
                                            DELETE
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };

            return renderOrchards();
        };

        // Determind render within two tabs layout or no tab layout
        if (displaySectors || displayLots) {
            return renderMultipleTabs(displaySectors, displayLots);
        } else {
            return renderOrchardsTabContent();
        }
    }

    renderSiteBlockRow(indexData) {
        const { orchardIndex, blockId, areaId } = indexData;
        let block = null;
        let area = null;
        let site = Object.assign({}, this.emptySite);
        let id = '';
        if (orchardIndex > -1) {
            // setSite
            id = orchardIndex;
            if (!this.props.orchardSites[parseInt(orchardIndex, 10)].blocks)
                this.props.orchardSites[parseInt(orchardIndex, 10)].blocks = [];
            site = Object.assign({}, this.props.orchardSites[parseInt(id, 10)]);
            const blockIndex = site.blocks.findIndex(item => item.id === blockId);
            // setBlock
            if (blockId !== undefined) {
                if (blockIndex > -1) {
                    id = blockIndex;
                    if (this.state.block == null) {
                        if (this.state.site === null) {
                            site = this.props.orchardSites[parseInt(this.state.id, 10)];
                        }
                    }

                    block = Object.assign({}, site.blocks[parseInt(id, 10)]);

                    if (!block.areas) block.areas = [];
                    if (!block.newRows) block.newRows = [];
                    if (!block.varieties) block.varieties = [];
                    block.varieties = block.varieties.slice();

                    // setSub-block (Area)
                    if (areaId !== undefined) {
                        const areaIndex = block.areas.findIndex(item => item.id === areaId);
                        id = areaIndex;
                        if (areaIndex > -1) {
                            id = areaIndex;
                            area = Object.assign({}, block.areas[parseInt(id, 10)]);
                            if (!area.newRows) area.newRows = [];
                            if (!area.varieties) area.varieties = [];
                            area.varieties = area.varieties.slice();
                        } else {
                            area = Object.assign({}, this.emptySite);
                            area.varieties = [];
                        }
                    }
                } else {
                    block = Object.assign({}, this.emptySite);
                    block.varieties = [];
                }
            }
        }

        const newState = {
            id: id.toString(),
            block,
            area,
            row: null,
            site,
        };
        this.setState(newState);
    }

    render() {
        return (
            <>
                <div data-w-tab="Admin" className="frame-pane w-tab-pane w--tab-active">
                    <div className="w-tab-content">
                        <Backdrop
                            sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                            open={this.state.loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {['sites', 'sectors', 'lots'].indexOf(this.state.mode) >= 0 &&
                            this.renderOrchardSites({ sectorArr: this.props.sectors, lotArr: this.props.lots })}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(state => state.metadata, {
        ...MetadataState.actionCreators,
        fetchLots,
        submitLot,
        deleteLot,
        fetchSectors,
        submitSector,
        deleteSector,
        fetchOrchardSites,
        fetchVarieties,
    })(Admin)
);
