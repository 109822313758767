export enum ApiEndpoint {
    Metadata = 'api/metadata',
    MetadataPayroll = 'api/metadata/payroll',
    MetadataOrchardSites = 'api/metadata/orchardSites',
    MetadataOrchardSitesv2 = 'api/metadata/v2/orchardSites',
    MetadataSectors = 'api/metadata/sectors',
    MetadataVarieties = 'api/metadata/varieties',
    MetadataJobs = 'api/metadata/jobs',
    MetadataNZAP = 'api/nzap',
    MetadataNZAPMapping = 'api/nzap/mapping',
    MetadataHarvestExportSettings = 'api/metadata/harvest-export-settings',
    PayrollReportDownload = 'download/payroll-report',
    PayrollExportDownload = 'download/payroll-export',
    MetadataJobRateUnits = 'api/metadata/job_rate_units',
    PayrollAdminSettings = 'api/metadata/payroll/admin-settings',
    PayrollList = 'api/payroll',
    LockedPayRun = 'api/lock_payruns',
    UnLockPayRun = 'api/unlock_payrun',
    UserPreferencesDisplay = 'api/userPreferences/display',
    Timesheet = 'api/timesheet',
    TimesheetReport = 'download/timesheet-report',
    MetadataExportCountries = 'api/metadata/export-countries',
    SaveTaskTypes = 'api/savetasktypes',
    SwitchOrchard = 'api/switchorchards',
    BugFeedBack = 'api/bugs-feedbacks',
    TasksData = 'api/tasksdata',
    Insights = 'api/insights',
    Bins = 'api/bins',
    BackdateWageRate = 'api/backdateWageRate',
    Lots = 'api/metadata/lots',
    OrchardSitesAndBlocks = 'api/metadata/orchardSitesAndBlocks',
    VarietiesDefaults = 'api/metadata/varieties/defaults',
    SaveData = 'api/save',
    Staff = 'api/users',
    StaffProfileImage = 'api/users/profileImage',
    VarietiesPicks = 'api/metadata/picks',
    VarietiesQualities = 'api/metadata/qualities',
    VarietiesQCDefects = 'api/metadata/qcdefects',
    ValidateStaff = 'api/users/validate-import',
    BulkImport = 'api/users/import',
    GeneralSettings = 'api/saveGeneralSettings',
    LunchBreakSettings = 'api/saveLunchBreakSettings',
    DeviceSettings = 'api/saveDeviceSettings',
    MetadataLeaves = 'api/metadata/leaves',
    Login = 'api/login',
    Logout = 'api/logout',
    ForgotPassword = 'api/users/reset-password',
    DateInfoForLocking = 'api/date_info_for_locking',
    OrchardSiteFruitTypes = 'api/metadata/orchardSites/fruitTypes',
    GetReports = 'api/getReports',
    Harvest = 'api/harvest',
    HarvestTalliesExport = 'download/harvest',
    HarvestAllDataExport = 'download/data',
    HarvestCsvExport = 'api/harvest/downloads/csv-export',
    RefreshToken = 'api/refresh-token',
}

export enum PayrollServiceEndpoint {
    TimesheetsEmployee = '/timesheets/employee',
    Timesheet = '/timesheet',
    TenantLocation = '/location/tenant',
    Reports = '/reportbuilder/reports',
    ReportData = '/reportbuilder/report/{report_uuid}/data',
    ReportConfiguration = '/reportbuilder/report/{report_uuid}/configuration',
    ReportConfigurationColumn = '/reportbuilder/report/{report_uuid}/column',
}

export enum FrontendPath {
    Localization = 'localisation',
}

export enum SpectreV3ApiEndpoint {
    Groups = '/configuration/v3-0-0/groups',
    SizeBand = '/configuration/v3-0-0/grader_run/size-band',
    GraderRun = '/configuration/v3-0-0/grader_run/grader-run',
    GraderRunResults = '/configuration/v3-0-0/grader_run/grader-run/{id}/results',
}

export enum SpectreDashboardApiEndpoint {
    Groups = '/dashboard/bin-group',
}
