import { useContext } from 'react';
import { AuthDetails } from 'src/models/auth';
import { AuthContext } from 'src/providers/AuthProvider';

export const useAuth = (): AuthDetails => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
