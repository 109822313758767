import * as $ from 'jquery';
import * as moment from 'moment';
import * as Cookies from 'js-cookie';
import { Utils } from '../store/Utils';
import { notify } from 'src/components_v2/common/Notification';
import { NotificationMessage } from 'src/DataContracts/NotificationMessage';
import config from '../config';
import { postData } from 'src/ApiService/httpMethods';
import { ApiEndpoint } from 'src/ApiService/ApiEndpoint';
import { convertJsonToFormData } from 'src/helpers/form';
import { validateMaximumSelectedPeriod } from 'src/helpers/date';

export const getHeaders = Utils.getHeaders;
export const fetchPayroll = Utils.fetchPayroll;
export const fetchSectorOrchardBlocks = Utils.fetchSectorOrchardBlocks;

export const handleFilterSector = sectorId => (dispatch, getState) => {
    if (sectorId === 'all') {
        const orchardIds = getState()
            .maps.filter.sectorList.map(sec => sec.orchardSiteIds)
            .flat();
        const orchardsAndBlocks = getState().maps.filter.orchardsAndBlocks;
        const selectedOrchards = orchardsAndBlocks.filter(item => orchardIds.includes(item.id));
        const blockList = selectedOrchards.reduce((acc, item) => {
            acc = [...acc, ...item.blocks];
            return acc;
        }, []);
        // if select all return empty
        dispatch({
            type: 'UPDATE_SECTOR_FILTER',
            payload: [],
        });

        // update orchard list belongs to selected sectors
        dispatch({
            type: 'UPDATE_ORCHARDS_LIST',
            payload: selectedOrchards,
        });

        // update block list belongs to selected sectors
        dispatch({
            type: 'UPDATE_BLOCK_LIST',
            payload: blockList,
        });
    } else {
        const sectorSelect = getState().maps.filter.sectorList.find(item => item.id === sectorId);
        const orchardIds = sectorSelect.orchardSiteIds;
        const selectedOrchards = getState().maps.filter.orchardsAndBlocks.filter(item => orchardIds.includes(item.id));
        const selectedBlocks = selectedOrchards.reduce((acc, item) => {
            acc = [...acc, ...item.blocks];
            return acc;
        }, []);
        const payload = [sectorSelect.id];

        // select sector
        dispatch({
            type: 'UPDATE_SECTOR_FILTER',
            payload,
        });

        // update orchard list belongs to selected sectors
        dispatch({
            type: 'UPDATE_ORCHARDS_LIST',
            payload: selectedOrchards,
        });

        // clear selected orchard
        dispatch({
            type: 'UPDATE_ORCHARDS_FILTER',
            payload: [],
        });

        // update block list belongs to selected sectors
        dispatch({
            type: 'UPDATE_BLOCK_LIST',
            payload: selectedBlocks,
        });

        // clear selected block
        dispatch({
            type: 'UPDATE_BLOCK_FILTER',
            payload: [],
        });
    }
};

export const handleFilterOrchard = orchardId => (dispatch, getState) => {
    if (orchardId === 'all') {
        const orchardList = getState().maps.filter.orchardList;
        const blockList = orchardList.reduce((acc, item) => {
            acc = [...acc, ...item.blocks];
            return acc;
        }, []);
        // if select all return empty
        dispatch({
            type: 'UPDATE_ORCHARDS_FILTER',
            payload: [],
        });

        dispatch({
            type: 'UPDATE_BLOCK_LIST',
            payload: blockList,
        });
    } else {
        const orchard = getState().maps.filter.orchardsAndBlocks.find(item => item.id === orchardId);
        const payload = orchard.blocks || [];
        // update block list belongs to selected orchard
        dispatch({
            type: 'UPDATE_BLOCK_LIST',
            payload,
        });

        // select orchard
        dispatch({
            type: 'UPDATE_ORCHARDS_FILTER',
            payload: [orchard.id],
        });

        // clear selected block
        dispatch({
            type: 'UPDATE_BLOCK_FILTER',
            payload: [],
        });
    }
};

export const handleFilterBlock = blockId => (dispatch, getState) => {
    let payload;
    if (blockId === 'all') {
        // if select all return empty
        payload = [];
    } else {
        const block = getState().maps.filter.blockList.find(item => item.id === blockId);
        payload = [block.name];
    }
    dispatch({
        type: 'UPDATE_BLOCK_FILTER',
        payload,
    });
};

export const actionCreators = {
    requestData: (self, startDate, endDate) => async (dispatch, getState) => {
        try {
            if (startDate && endDate) {
                self.setState({
                    startDate: startDate,
                    endDate: endDate,
                });
            }

            var selectedStartDate = startDate ?? self.state.startDate;
            var selectedEndDate = endDate ?? self.state.endDate;

            if (validateMaximumSelectedPeriod(selectedStartDate, selectedEndDate)) {
                const orchardFilter = await getState().maps.filter.orchardFilter;

                const siteObj =
                    orchardFilter.length > 0
                        ? await getState().maps.filter.orchardsAndBlocks.find(item => item.id === orchardFilter[0])
                        : {};

                let orchardSite = $('#SiteId').length > 0 ? $('#SiteId').val() : '';
                let orchardName = '';

                if (orchardSite.includes('_')) {
                    orchardName = orchardSite.split('_')[1];
                    orchardSite = orchardSite.split('_')[0];
                }

                const formData = convertJsonToFormData({
                    StartDate: selectedStartDate.toDateString(),
                    EndDate: selectedEndDate.toDateString(),
                    SectorId:
                        (await getState().maps.filter.sectorFilter.length) > 0
                            ? getState().maps.filter.sectorFilter[0]
                            : null,
                    SiteId: siteObj.site ? siteObj.site : null,
                    SiteName: siteObj.name ? siteObj.name : null,
                    BlockId:
                        (await getState().maps.filter.blockFilter.length) > 0
                            ? getState().maps.filter.blockFilter[0]
                            : null,
                    PickerId: $('#PickerId').length > 0 ? $('#PickerId').val() : null,
                    Variety: $('#Variety').length > 0 ? $('#Variety').val() : null,
                    PickType: $('#PickType').length > 0 ? $('#PickType').val() : null,
                });

                if (self.heatmap) self.heatmap.setMap(null);
                for (let i = 0; i < self.markers.length; i++) self.markers[i].setMap(null);
                self.markers = [];

                await postData(ApiEndpoint.Bins, formData).then(response => {
                    if (response.code !== 0) throw response.message.content;
                    const binsWithLocation = response.response ?? [];
                    var heatMapData = [];
                    const showMarkers = binsWithLocation.length <= 1000;

                    if (binsWithLocation.length > 0) {
                        const firstBinWithLocation = binsWithLocation[0];
                        self.map.setCenter(
                            new window.google.maps.LatLng(firstBinWithLocation.latitude, firstBinWithLocation.longitude)
                        );
                        self.map.setZoom(12);

                        for (var i = 0; i < binsWithLocation.length; i++) {
                            var bin = binsWithLocation[i];
                            heatMapData.push(new window.google.maps.LatLng(bin.latitude, bin.longitude));

                            const orchard =
                                bin.orchardName == null || bin.orchardName === bin.orchardSite
                                    ? bin.orchardSite
                                    : `${bin.orchardName} (${bin.orchardSite})`;

                            if (showMarkers) {
                                self.markers.push(
                                    new window.google.maps.Marker({
                                        position: new window.google.maps.LatLng(bin.latitude, bin.longitude),
                                        map: self.map,
                                        icon: {
                                            path: window.google.maps.SymbolPath.CIRCLE,
                                            scale: 4,
                                            strokeOpacity: 0,
                                        },
                                        title:
                                            'Bin: ' +
                                            bin.id.slice(-4) +
                                            '\n' +
                                            moment(bin.dateTimestamp).format('DD MMM YYYY') +
                                            '\n' +
                                            (self.props.metadata?.localisations?.Site ?? 'Orchard') +
                                            ': ' +
                                            orchard +
                                            '\n' +
                                            'Block: ' +
                                            bin.block +
                                            '\n' +
                                            (bin.areas == null || bin.areas.length === 0
                                                ? ''
                                                : 'Area: ' + bin.areas?.map(x => x.area).join(', ') + '\n') +
                                            (bin.pickers == null || bin.pickers.length === 0
                                                ? ''
                                                : 'Picker(s): ' +
                                                  bin.pickers?.map(x => x.pickerName).join(', ') +
                                                  '\n') +
                                            'Variety: ' +
                                            bin.variety +
                                            '\n' +
                                            bin.teamLeaderName,
                                    })
                                );
                            }
                        }
                    }

                    self.heatmap = new window.google.maps.visualization.HeatmapLayer({
                        data: heatMapData,
                        map: self.map,
                        dissipating: true,
                    });

                    if (binsWithLocation.length > 0) {
                        notify({
                            message: `${binsWithLocation.length} ${binsWithLocation.length === 1 ? 'bin' : 'bins'} loaded`,
                        });
                    } else {
                        notify({
                            message: 'Could not find any bins',
                            type: 'warning',
                        });
                    }
                });
            }
        } catch (e) {
            console.log(e);
            notify({ message: 'Unable to load bins', type: 'error' });
        }
    },
};

const unloadedState = {
    filter: {
        sectorList: [],
        orchardList: [],
        blockList: [],
        sectorFilter: [],
        orchardsAndBlocks: [],
        orchardFilter: [],
        blockFilter: [],
    },
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_SECTOR_NAMES':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    sectorList: action.payload,
                },
            };
        case 'UPDATE_SECTOR_FILTER':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    sectorFilter: action.payload,
                },
            };
        case 'UPDATE_ORCHARDS_LIST':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orchardList: action.payload,
                },
            };
        case 'UPDATE_ORCHARDS_FILTER':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orchardFilter: action.payload,
                },
            };
        case 'FETCH_ORCHARDS_AND_BLOCKS':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orchardsAndBlocks: action.payload.orchardsAndBlocks,
                    orchardList: action.payload.orchardList,
                    blockList: action.payload.orchardsAndBlocks.reduce((acc, item) => {
                        acc = [...acc, ...item.blocks];
                        return acc;
                    }, []),
                },
            };
        case 'UPDATE_BLOCK_LIST':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    blockList: action.payload,
                },
            };
        case 'UPDATE_BLOCK_FILTER':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    blockFilter: action.payload,
                },
            };
        default:
            return state || unloadedState;
    }
};
