import config from '../../../config';
declare global {
    interface Window {
        Beacon: any;
    }
}

const Beacon = (pageKeyValue: BeaconPage) => {
    window.Beacon('suggest', config.BEACONSUGGEST?.get(pageKeyValue));
};

export enum BeaconPage {
    Harvest = 'Harvest',
    Payroll = 'Payroll',
    Timesheets = 'Timesheets',
    Insights = 'Insights',
    Scout = 'Scout',
    Jobs = 'Jobs',
    General = 'General',
    Staff = 'Staff',
    Varieties = 'Varieties',
    Default = 'Default',
    Spectre = 'Spectre',
}

export default Beacon;
