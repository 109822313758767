import { useRef, useEffect, useState } from 'react';
import Beacon, { BeaconPage } from 'src/components_v2/common/Beacon';
import config from 'src/config';
import { ensureTrailingForwardSlash } from 'src/helpers/string';
import { handleCrossPlatformWindowMessageEvents } from 'src/helpers/crossPlatformMessageHandler';
import { useAuth } from 'src/hooks/useAuth';

const LocationLandingPage = () => {
    const iframeRef = useRef(null);
    const locationModuleRootUrl = ensureTrailingForwardSlash(config.LOCATION_MODULE_ROOT_URL);
    const authDetails = useAuth();

    const [isFullScreen, setIsFullScreen] = useState(false);

    /** Message Channels are initialized during the "HANDSHAKE" event */
    let channel: MessageChannel | null = null;

    useEffect(() => {
        Beacon(BeaconPage.Default);
        window.addEventListener(
            'message',
            event =>
                handleCrossPlatformWindowMessageEvents({
                    iframeRef,
                    authDetails,
                    channel,
                    message: event,
                    setIsFullScreen: setIsFullScreen,
                }),
            false
        );
    }, []);

    return (
        <div
            style={
                isFullScreen
                    ? {
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          width: '100%',
                          height: '100%',
                          border: 'none',
                          margin: 0,
                          padding: 0,
                          overflow: 'hidden',
                          zIndex: 999999,
                          background: 'white',
                      }
                    : { height: 'inherit' }
            }
        >
            <iframe
                ref={iframeRef}
                src={locationModuleRootUrl}
                id="location-frontend"
                sandbox="allow-same-origin allow-scripts"
                className="tw-w-full tw-h-full"
            ></iframe>
        </div>
    );
};

export default LocationLandingPage;
